import { z } from 'zod';

import {
  AnswerVisibility,
  ErrorCode,
  QuestionType,
  ReviewAssignmentStatus,
  ReviewCycleStatus,
  ReviewFrequency,
  ReviewStatus,
  ReviewType,
  Sentiment,
  SubscriptionTierType,
  TeamMemberRole,
  UserPermission,
  WeekDay,
} from './enums';

/**
 * Common
 */

export const apiErrorSchema = z.object({
  code: z.nativeEnum(ErrorCode),
  message: z.string(),
  details: z.array(z.record(z.string(), z.string())).optional(),
});

export type ApiError = z.infer<typeof apiErrorSchema>;

export const paginationSchema = z.object({
  totalItems: z.number(),
});

export const availabilityResponseSchema = z
  .object({
    data: z.array(
      z.object({
        id: z.string(),
        name: z.string(),
        available: z.boolean(),
        activeFor: z.string().optional(),
      }),
    ),
  })
  .merge(paginationSchema);

export const namedEntitySchema = z.object({
  id: z.string(),
  name: z.string(),
  active: z.boolean(),
});

export const namedEntityPagedResponseSchema = z
  .object({
    data: z.array(namedEntitySchema),
  })
  .merge(paginationSchema);

export type PaginationParams = {
  page: number;
  size: number;
  sort?: string[];
};

export type NamedEntity = z.infer<typeof namedEntitySchema>;

/**
 * Auth
 */

export const signInPayloadSchema = z.object({
  email: z.string(),
  password: z.string(),
});

export const signInResponseSchema = z.object({
  token: z.string(),
  expire: z.string(),
  userId: z.string(),
  organizationId: z.string(),
});

export const signOutResponseSchema = z.boolean();

export type SignInPayload = z.infer<typeof signInPayloadSchema>;

/**
 * Organization
 */

export const subscriptionTierSchema = z.object({
  id: z.string(),
  type: z.nativeEnum(SubscriptionTierType),
  expire: z.string().optional(),
  active: z.boolean(),
  created: z.string(),
  updated: z.string(),
});

export const organizationSchema = z.object({
  id: z.string(),
  name: z.string(),
  tier: subscriptionTierSchema,
  removalRequested: z.string().optional(),
  created: z.string(),
  updated: z.string(),
});

export const createOrganizationPayloadSchema = z.object({
  name: z.string(),
  email: z.string(),
  firstName: z.string(),
  lastName: z.string(),
});

export const updateOrganizationPayloadSchema = z.object({
  name: z.string(),
});

export const organizationResponseSchema = z.object({
  organization: organizationSchema,
});

export type Organization = z.infer<typeof organizationSchema>;
export type CreateOrganizationPayload = z.infer<typeof createOrganizationPayloadSchema>;
export type UpdateOrganizationPayload = z.infer<typeof updateOrganizationPayloadSchema>;

/**
 * User
 */

export const userSchema = z.object({
  id: z.string(),
  email: z.string(),
  name: z.string(),
  firstName: z.string(),
  lastName: z.string(),
  confirmed: z.boolean(),
  active: z.boolean(),
  created: z.string(),
  updated: z.string(),
});

export const userPermissionsSchema = z.object({
  permissions: z.array(z.nativeEnum(UserPermission)),
});

export const createUserPayloadSchema = z.object({
  email: z.string(),
  firstName: z.string(),
  lastName: z.string(),
});

export const updateUserPayloadSchema = z.object({
  firstName: z.string(),
  lastName: z.string(),
});

export const updateUserPermissionsSchema = userPermissionsSchema;

export const changePasswordPayloadSchema = z.object({
  password: z.string(),
  currentPassword: z.string(),
});

export const userResponseSchema = z.object({
  user: userSchema,
});

export const usersResponseSchema = z
  .object({
    users: z.array(userSchema),
  })
  .merge(paginationSchema);

export const forgottenPasswordPayloadSchema = z.object({
  email: z.string(),
});

export const forgottenPasswordResponseSchema = z.boolean();

export const setPasswordPayloadSchema = z.object({
  password: z.string(),
});

export const setPasswordResponseSchema = z.boolean();

export type User = z.infer<typeof userSchema>;
export type CreateUserPayload = z.infer<typeof createUserPayloadSchema>;
export type UpdateUserPayload = z.infer<typeof updateUserPayloadSchema>;
export type UpdateUserPermissionsPayload = z.infer<typeof userPermissionsSchema>;
export type ChangePasswordPayload = z.infer<typeof changePasswordPayloadSchema>;
export type ForgottenPasswordPayload = z.infer<typeof forgottenPasswordPayloadSchema>;
export type SetPasswordPayload = z.infer<typeof setPasswordPayloadSchema>;

export type FetchUsersFilters = {
  email: string | undefined;
  permission: UserPermission | undefined;
  name: string | undefined;
  'first-name': string | undefined;
  'last-name': string | undefined;
  confirmed: boolean | undefined;
  'include-inactive': boolean | undefined;
  ids: string[] | undefined;
  'exclude-ids': string[] | undefined;
};

/**
 * Team
 */

export const teamSchema = z.object({
  id: z.string(),
  name: z.string(),
  description: z.string().optional(),
  active: z.boolean(),
  created: z.string(),
  updated: z.string(),
});

export const createTeamPayloadSchema = z.object({
  name: z.string(),
});

export const updateTeamPayloadSchema = z.object({
  name: z.string(),
});

export const teamResponseSchema = z.object({
  team: teamSchema,
});

export const teamsResponseSchema = z
  .object({
    teams: z.array(teamSchema),
  })
  .merge(paginationSchema);

export type Team = z.infer<typeof teamSchema>;
export type CreateTeamPayload = z.infer<typeof createTeamPayloadSchema>;
export type UpdateTeamPayload = z.infer<typeof updateTeamPayloadSchema>;

export type FetchTeamsFilters = {
  name: string | undefined;
  'include-inactive': boolean | undefined;
  ids: string[] | undefined;
  'exclude-ids': string[] | undefined;
};

/**
 * Team Member
 */

export const teamMemberSchema = z.object({
  id: z.string(),
  team: namedEntitySchema,
  user: namedEntitySchema,
  role: z.nativeEnum(TeamMemberRole),
  active: z.boolean(),
  created: z.string(),
  updated: z.string(),
});

export const createTeamMembersPayloadSchema = z.object({
  teamMembers: z.array(
    z.object({
      teamId: z.string(),
      userId: z.string(),
      role: z.nativeEnum(TeamMemberRole),
    }),
  ),
});

export const createTeamMembersResponseSchema = z.object({
  teamMembers: z.array(teamMemberSchema),
});

export const teamMemberResponseSchema = z.object({
  teamMember: teamMemberSchema,
});

export const teamMembersResponseSchema = z
  .object({
    teamMembers: z.array(teamMemberSchema),
  })
  .merge(paginationSchema);

export type TeamMember = z.infer<typeof teamMemberSchema>;
export type CreateTeamMembersPayload = z.infer<typeof createTeamMembersPayloadSchema>;

export type FetchTeamMembersFilters = {
  'team-ids': string[] | undefined;
  'user-ids': string[] | undefined;
  'user-name': string | undefined;
  'first-name': string | undefined;
  'last-name': string | undefined;
  'team-name': string | undefined;
  role: TeamMemberRole | undefined;
  'exclude-ids': string[] | undefined;
  'include-inactive': boolean | undefined;
};

/**
 * Review
 */

export const reviewCycleRecurringSettingsSchema = z.object({
  frequency: z.nativeEnum(ReviewFrequency),
  interval: z.number(),
  daysOfWeek: z.array(z.nativeEnum(WeekDay)).optional(),
  neverEnding: z.boolean().optional(),
  count: z.number().optional(),
  until: z.string().optional(),
});

export const reviewCycleSettingsSchema = z.object({
  firstCycleAtStart: z.boolean(),
  firstCycleAt: z.string().optional(),
  start: z.string().optional(),
  nextCycle: z.string().optional(),
  lastCycle: z.string().optional(),
  duration: z.string(),
  occurrences: z.number(),
  remindIncompleteDuration: z.string().optional(),
  recurringSettings: reviewCycleRecurringSettingsSchema.optional(),
});

export const reviewSchema = z.object({
  id: z.string(),
  name: z.string(),
  description: z.string().optional(),
  type: z.nativeEnum(ReviewType),
  status: z.nativeEnum(ReviewStatus),
  cycleSettings: reviewCycleSettingsSchema.optional(),
  active: z.boolean(),
  created: z.string(),
  updated: z.string(),
});

export const questionOptionSchema = z.object({
  value: z.string(),
  sentiment: z.nativeEnum(Sentiment).optional(),
});

export const questionSchema = z.object({
  id: z.string(),
  position: z.number(),
  type: z.nativeEnum(QuestionType),
  text: z.string(),
  required: z.boolean(),
  answerVisibility: z.nativeEnum(AnswerVisibility),
  options: z.array(questionOptionSchema).optional(),
  created: z.string(),
  updated: z.string(),
});

export const sectionSchema = z.object({
  id: z.string(),
  position: z.number(),
  title: z.string(),
  description: z.string().optional(),
  questions: z.array(questionSchema),
  created: z.string(),
  updated: z.string(),
});

export const createReviewPayloadSchema = z.object({
  name: z.string(),
  type: z.nativeEnum(ReviewType),
});

export const updateReviewPayloadSchema = z.object({
  name: z.string(),
});

export const updateReviewCycleRecurringSettingsPayloadSchema = z.object({
  frequency: z.nativeEnum(ReviewFrequency),
  interval: z.number(),
  daysOfWeek: z.array(z.nativeEnum(WeekDay)).optional(),
  neverEnding: z.boolean().optional(),
  count: z.number().optional(),
  until: z.string().optional(),
});

export const updateReviewCycleSettingsPayloadSchema = z.object({
  firstCycleAtStart: z.boolean(),
  firstCycleAt: z.string().optional(),
  duration: z.string(),
  remindIncompleteDuration: z.string().optional(),
  recurringSettings: updateReviewCycleRecurringSettingsPayloadSchema.optional(),
});

export const reviewResponseSchema = z.object({
  review: reviewSchema,
});

export const reviewValidationResponseSchema = z.object({
  errors: z.array(apiErrorSchema),
});

export const reviewsResponseSchema = z
  .object({
    reviews: z.array(reviewSchema),
  })
  .merge(paginationSchema);

export const createSectionPayloadSchema = z.object({
  title: z.string(),
  description: z.string().optional(),
});

export const updateSectionPayloadSchema = z.object({
  title: z.string(),
  description: z.string().optional(),
});

export const updateSectionPositionPayloadSchema = z.object({
  position: z.number(),
});

export const deleteSectionResponseSchema = z.boolean();

export const sectionResponseSchema = z.object({
  section: sectionSchema,
});

export const sectionsResponseSchema = z.object({
  sections: z.array(sectionSchema),
});

export const createQuestionPayloadSchema = z.object({
  type: z.nativeEnum(QuestionType),
  text: z.string(),
  required: z.boolean(),
  answerVisibility: z.nativeEnum(AnswerVisibility),
  options: z.array(questionOptionSchema).optional(),
});

export const updateQuestionPayloadSchema = z.object({
  type: z.nativeEnum(QuestionType),
  text: z.string(),
  required: z.boolean(),
  answerVisibility: z.nativeEnum(AnswerVisibility),
  options: z.array(questionOptionSchema).optional(),
});

export const deleteQuestionResponseSchema = z.boolean();

export const questionResponseSchema = z.object({
  question: questionSchema,
});

export const updateQuestionSectionPositionPayloadSchema = z.object({
  sectionId: z.string(),
  position: z.number(),
});

export type ReviewResponse = z.infer<typeof reviewResponseSchema>;
export type ReviewCycleRecurringSettings = z.infer<typeof reviewCycleRecurringSettingsSchema>;
export type Review = z.infer<typeof reviewSchema>;
export type QuestionOption = z.infer<typeof questionOptionSchema>;
export type Question = z.infer<typeof questionSchema>;
export type Section = z.infer<typeof sectionSchema>;
export type CreateReviewPayload = z.infer<typeof createReviewPayloadSchema>;
export type UpdateReviewPayload = z.infer<typeof updateReviewPayloadSchema>;
export type UpdateReviewCycleSettingsPayload = z.infer<
  typeof updateReviewCycleSettingsPayloadSchema
>;
export type UpdateReviewCycleRecurringSettingsPayload = z.infer<
  typeof updateReviewCycleRecurringSettingsPayloadSchema
>;
export type CreateSectionPayload = z.infer<typeof createSectionPayloadSchema>;
export type UpdateSectionPayload = z.infer<typeof updateSectionPayloadSchema>;
export type UpdateSectionPositionPayload = z.infer<typeof updateSectionPositionPayloadSchema>;
export type CreateQuestionPayload = z.infer<typeof createQuestionPayloadSchema>;
export type UpdateQuestionPayload = z.infer<typeof updateQuestionPayloadSchema>;
export type UpdateQuestionSectionPositionPayload = z.infer<
  typeof updateQuestionSectionPositionPayloadSchema
>;

export type FetchReviewsFilters = {
  name: string | undefined;
  type: ReviewType | undefined;
  ids: string[] | undefined;
  'exclude-ids': string[] | undefined;
  'include-inactive': boolean | undefined;
};

/**
 * Review Template
 */

export const reviewTemplateSchema = z.object({
  id: z.string(),
  name: z.string(),
  description: z.string(),
  frequency: z.nativeEnum(ReviewFrequency),
  duration: z.string(),
  interval: z.number(),
  daysOfWeek: z.array(z.nativeEnum(WeekDay)).optional(),
  type: z.nativeEnum(ReviewType),
  sections: z.array(
    z.object({
      title: z.string(),
      description: z.string(),
      position: z.number(),
      questions: z.array(
        z.object({
          type: z.nativeEnum(QuestionType),
          text: z.string(),
          required: z.boolean(),
          position: z.number(),
          answerVisibility: z.nativeEnum(AnswerVisibility),
          options: z.array(
            z.object({
              value: z.string(),
            }),
          ),
        }),
      ),
    }),
  ),
});

export const createReviewFromTemplatePayloadSchema = z.object({
  name: z.string(),
});

export const reviewTemplatesResponseSchema = z
  .object({
    reviewTemplates: z.array(reviewTemplateSchema),
  })
  .merge(paginationSchema);

export type CreateReviewFromTemplatePayload = z.infer<typeof createReviewFromTemplatePayloadSchema>;

export type ReviewTemplate = z.infer<typeof reviewTemplateSchema>;

export type FetchReviewTemplatesFilters = {
  name: string | undefined;
  type: ReviewType | undefined;
};

/**
 * Review Team
 */

export const reviewTeamSchema = z.object({
  id: z.string(),
  review: namedEntitySchema,
  team: namedEntitySchema,
  active: z.boolean(),
  created: z.string(),
  updated: z.string(),
});

export const createReviewTeamsPayloadSchema = z.object({
  reviewTeams: z.array(
    z.object({
      reviewId: z.string(),
      teamId: z.string(),
    }),
  ),
});

export const createReviewTeamsResponseSchema = z.object({
  reviewTeams: z.array(reviewTeamSchema),
});

export const reviewTeamResponseSchema = z.object({
  reviewTeam: reviewTeamSchema,
});

export const reviewTeamsResponseSchema = z
  .object({
    reviewTeams: z.array(reviewTeamSchema),
  })
  .merge(paginationSchema);

export type ReviewTeam = z.infer<typeof reviewTeamSchema>;
export type CreateReviewTeamsPayload = z.infer<typeof createReviewTeamsPayloadSchema>;

export type FetchReviewTeamsFilters = {
  'review-ids': string[] | undefined;
  'review-name': string | undefined;
  'team-ids': string[] | undefined;
  'team-name': string | undefined;
  ids: string[] | undefined;
  'exclude-ids': string[] | undefined;
  'include-inactive': boolean | undefined;
};

/**
 * Review Cycle
 */

export const reviewCycleSchema = z.object({
  id: z.string(),
  status: z.nativeEnum(ReviewCycleStatus),
  start: z.string(),
  end: z.string(),
  remindIncomplete: z.string().optional(),
  reminded: z.boolean(),
  active: z.boolean(),
  created: z.string(),
  updated: z.string(),
});

export const reviewCyclesResponseSchema = z
  .object({
    reviewCycles: z.array(reviewCycleSchema),
  })
  .merge(paginationSchema);

export const reviewCycleResponseSchema = z.object({
  reviewCycle: reviewCycleSchema,
});

export type ReviewCycle = z.infer<typeof reviewCycleSchema>;

export type FetchReviewCyclesFilters = {
  status?: ReviewCycleStatus;
  ids?: string[];
  'include-inactive'?: boolean;
};

/**
 * Review Assignment
 */

export const reviewAssignmentSchema = z.object({
  id: z.string(),
  author: teamMemberSchema,
  recipient: teamMemberSchema,
  status: z.nativeEnum(ReviewAssignmentStatus),
  sentiment: z.nativeEnum(Sentiment),
  assigned: z.string(),
  opened: z.string().optional(),
  completed: z.string().optional(),
  published: z.string().optional(),
  collected: z.string().optional(),
  reviewTeam: reviewTeamSchema,
  reviewType: z.nativeEnum(ReviewType),
  reviewCycle: reviewCycleSchema,
  active: z.boolean(),
  created: z.string(),
  updated: z.string(),
});

export const reviewAssignmentsResponseSchema = z
  .object({
    reviewAssignments: z.array(reviewAssignmentSchema),
  })
  .merge(paginationSchema);

export const reviewAssignmentResponseSchema = z.object({
  reviewAssignment: reviewAssignmentSchema,
});

export const answerSchema = z.object({
  questionId: z.string(),
  values: z.array(z.string()),
  sentiment: z.nativeEnum(Sentiment),
});

export const saveAnswersRequestSchema = z.object({
  answers: z.array(answerSchema.omit({ sentiment: true })),
});

export const reviewAssignmentOutcomeResponseSchema = z.object({
  reviewAssignment: reviewAssignmentSchema,
  answers: z.array(answerSchema),
});

export type ReviewAssignment = z.infer<typeof reviewAssignmentSchema>;
export type ReviewAssignmentOutcome = z.infer<typeof reviewAssignmentOutcomeResponseSchema>;

export type Answer = z.infer<typeof answerSchema>;
export type SaveAnswersRequest = z.infer<typeof saveAnswersRequestSchema>;

export type FetchReviewAssignmentsFilters = {
  'author-user-name': string | undefined;
  'author-user-ids': string[] | undefined;
  'recipient-user-name': string | undefined;
  'recipient-user-ids': string[] | undefined;
  'team-name': string | undefined;
  'team-ids': string[] | undefined;
  statuses: ReviewAssignmentStatus[] | undefined;
  ids: string[] | undefined;
  'include-inactive': boolean | undefined;
};

export type FetchReviewAssignmentTeamsFilters = {
  name: string | undefined;
  'author-user-ids': string[] | undefined;
  'recipient-user-ids': string[] | undefined;
};

export type FetchReviewAssignmentRecipientsFilters = {
  name: string | undefined;
  'team-ids': string[] | undefined;
};

export type FetchReviewAssignmentAuthorsFilters = {
  name: string | undefined;
  'team-ids': string[] | undefined;
};

export type FetchReviewAssignmentsByAuthorFilters = {
  'recipient-user-name': string | undefined;
  'recipient-user-ids': string[] | undefined;
  'team-name': string | undefined;
  'team-ids': string[] | undefined;
  'review-name': string | undefined;
  'review-ids': string[] | undefined;
  statuses: ReviewAssignmentStatus[] | undefined;
};

export type FetchReviewAssignmentByAuthorTeamsFilters = {
  name: string | undefined;
  'review-ids': string[] | undefined;
  'recipient-user-ids': string[] | undefined;
};

export type FetchReviewAssignmentByAuthorRecipientsFilters = {
  name: string | undefined;
  'team-ids': string[] | undefined;
  'review-ids': string[] | undefined;
};

export type FetchReviewAssignmentByAuthorReviewsFilters = {
  name: string | undefined;
  'team-ids': string[] | undefined;
  'recipient-user-ids': string[] | undefined;
};

export type FetchReviewAssignmentsByRecipientFilters = {
  'author-user-name': string | undefined;
  'author-user-ids': string[] | undefined;
  'team-name': string | undefined;
  'team-ids': string[] | undefined;
  'review-name': string | undefined;
  'review-ids': string[] | undefined;
  statuses: ReviewAssignmentStatus[] | undefined;
};

export type FetchReviewAssignmentByRecipientTeamsFilters = {
  name: string | undefined;
  'review-ids': string[] | undefined;
  'author-user-ids': string[] | undefined;
};

export type FetchReviewAssignmentByRecipientAuthorsFilters = {
  name: string | undefined;
  'team-ids': string[] | undefined;
  'review-ids': string[] | undefined;
};

export type FetchReviewAssignmentByRecipientReviewsFilters = {
  name: string | undefined;
  'team-ids': string[] | undefined;
  'author-user-ids': string[] | undefined;
};

/**
 * Review Cycle Team
 */

export const reviewCycleTeamSchema = z.object({
  id: z.string(),
  reviewTeamId: z.string(),
  teamId: z.string(),
  teamName: z.string(),
  active: z.boolean(),
  created: z.string(),
  updated: z.string(),
});

export const reviewCycleTeamsResponseSchema = z
  .object({
    reviewCycleTeams: z.array(reviewCycleTeamSchema),
  })
  .merge(paginationSchema);

export const reviewCycleTeamResponseSchema = z.object({
  reviewCycleTeam: reviewCycleTeamSchema,
});

export type FetchReviewCycleTeamsFilters = {
  'team-ids'?: string[];
  'team-name'?: string;
  ids?: string[];
  'include-inactive'?: boolean;
};

/**
 * Observer
 */

export const observerReviewTeamSchema = z.object({
  id: z.string(),
  reviewTeam: reviewTeamSchema,
  active: z.boolean(),
  created: z.string(),
  updated: z.string(),
});

export const observerSchema = z.object({
  id: z.string(),
  review: namedEntitySchema,
  reviewType: z.nativeEnum(ReviewType),
  user: namedEntitySchema,
  observesAllReviewTeams: z.boolean(),
  observerReviewTeams: z.array(observerReviewTeamSchema).optional(),
  active: z.boolean(),
  created: z.string(),
  updated: z.string(),
});

export const createObserverPayloadSchema = z.object({
  reviewId: z.string(),
  userId: z.string(),
  observesAllReviewTeams: z.boolean(),
  reviewTeamIds: z.array(z.string()).optional(),
});

export const observerResponseSchema = z.object({
  observer: observerSchema,
});

export const observersResponseSchema = z
  .object({
    observers: z.array(observerSchema),
  })
  .merge(paginationSchema);

export type Observer = z.infer<typeof observerSchema>;
export type CreateObserverPayload = z.infer<typeof createObserverPayloadSchema>;

export type FetchObserversFilters = {
  'review-ids': string[] | undefined;
  'review-name': string | undefined;
  'user-ids': string[] | undefined;
  'user-name': string | undefined;
  'first-name': string | undefined;
  'last-name': string | undefined;
  'review-team-ids': string[] | undefined;
  'observes-all-review-teams': boolean | undefined;
  ids: string[] | undefined;
  'exclude-ids': string[] | undefined;
  'include-inactive': boolean | undefined;
  'include-inactive-observer-review-teams': boolean | undefined;
};

/**
 * Observatory
 */

export const observatoryReviewAssignmentsResponseSchema = z
  .object({
    reviewAssignmentOutcomes: z.array(reviewAssignmentOutcomeResponseSchema),
  })
  .merge(paginationSchema);

export type FetchObservatoryObserversFilters = {
  'review-name': string | undefined;
  'review-type': ReviewType | undefined;
};

export type FetchObservatoryReviewAssignmentsFilters = {
  'author-user-name': string | undefined;
  'recipient-user-name': string | undefined;
  'team-name': string | undefined;
  'review-cycle-ids': number[] | undefined; // TODO not used
  status: ReviewAssignmentStatus | undefined;
};

export type FetchObservatoryReviewCyclesFilters = {
  status: ReviewAssignmentStatus | undefined;
};

export type FetchObservatoryReviewTeamsFilters = {
  'team-name': string | undefined;
};
