/**
 * Common
 */

export const PAGINATION_DEFAULT_PAGE = 0;
export const PAGINATION_DEFAULT_SIZE = 10;

export const AVAILABILITY_MAX_LENGTH = 255;
export const AVAILABILITY_MIN_LENGTH = 1;

export const PASSWORD_MAX_LENGTH = 50;
export const PASSWORD_MIN_LENGTH = 8;
export const PASSWORD_PATTERN =
  /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!$%&()*+,./:;<=>?@[\]^_{|}~-])(?=\S+$).{8,}$/u;

/**
 * Organization
 */

export const ORGANIZATION_NAME_MAX_LENGTH = 255;
export const ORGANIZATION_NAME_MIN_LENGTH = 3;
export const ORGANIZATION_NAME_PATTERN = /^[A-Za-z0-9._\s-]+$/u;
export const ORGANIZATION_REMOVAL_WAIT_PERIOD_DAYS = 7;

/**
 * User
 */

export const USER_FIRST_NAME_MAX_LENGTH = 255;
export const USER_LAST_NAME_MAX_LENGTH = 255;

/**
 * Team
 */

export const TEAM_NAME_MAX_LENGTH = 255;
export const TEAM_DESCRIPTION_MAX_LENGTH = 512;

/**
 * Review
 */

export const REVIEW_NAME_MAX_LENGTH = 255;
export const REVIEW_DESCRIPTION_MAX_LENGTH = 512;
export const REVIEW_TEAMS_MAX_COUNT = 50;
export const REVIEW_SECTIONS_MAX_COUNT = 50;
export const QUESTION_TEXT_MAX_LENGTH = 1000;
export const QUESTION_ANSWER_TEXT_MAX_LENGTH = 2000;
export const QUESTION_OPTIONS_MAX_LENGTH = 20;
export const QUESTION_OPTION_MAX_LENGTH = 255;
