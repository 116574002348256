import { useEffect, useRef } from 'react';

type Ref = {
  focus: VoidFunction;
  blur: VoidFunction;
};

// Workaround for autoFocus not working in Modal
const useAutoFocus = <T extends Ref>() => {
  const ref = useRef<T>(null);

  useEffect(() => ref.current?.focus(), []);

  return ref;
};

export default useAutoFocus;
