import { useCallback, useState, useMemo } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { type SelectProps } from 'antd';
import { SearchOutlined, LoadingOutlined } from '@ant-design/icons';

import Select from '../Select';

const DEBOUNCE_MS = 500;

const AutoComplete = ({ loading, ...props }: SelectProps) => (
  <Select
    allowClear
    showSearch
    notFoundContent={false}
    filterOption={false}
    suffixIcon={loading ? <LoadingOutlined /> : <SearchOutlined />}
    loading={loading}
    {...props}
  />
);

AutoComplete.useAutoComplete = function useAutoComplete() {
  const [searchValue, setSearchValue] = useState<string>();

  const handleSearch = useDebouncedCallback(
    (v?: string) => setSearchValue(v === '' ? undefined : v),
    DEBOUNCE_MS,
  );

  const handleSelect = useCallback(() => setSearchValue(undefined), []);

  return useMemo(
    () => ({
      searchValue,
      handleSearch,
      handleSelect,
    }),
    [searchValue, handleSearch, handleSelect],
  );
};

export default AutoComplete;
