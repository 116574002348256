import { useCallback } from 'react';
import { Typography } from 'antd';
import { type TextProps } from 'antd/es/typography/Text';
import { type ParagraphProps } from 'antd/es/typography/Paragraph';
import { type TitleProps } from 'antd/es/typography/Title';
import { css } from '@emotion/react';

import { createStyles } from '@/common/styleUtils';

type BaseProps<P> = Omit<P, 'editable' | 'onChange' | 'children'> & {
  Component: typeof Typography.Text | typeof Typography.Paragraph | typeof Typography.Title;
  onChange: (value: string) => void;
  onCancel?: VoidFunction;
  onStart?: VoidFunction;
  onEnd?: VoidFunction;
  minLength?: number;
  maxLength?: number;
  value?: string;
  placeholder?: string;
  editing?: boolean;
};

const BaseInlineInput = <P,>({
  Component,
  onChange,
  onCancel,
  onStart,
  onEnd,
  minLength,
  maxLength,
  value,
  placeholder,
  editing,
  ...props
}: BaseProps<P>) => {
  const handleChange = useCallback(
    (val: string) => {
      if (val !== value && (minLength === undefined || val.length >= minLength)) {
        onChange(val);
      }
      onEnd?.();
    },
    [onChange, onEnd, minLength, value],
  );

  return (
    <Component
      editable={{
        enterIcon: null, // eslint-disable-line unicorn/no-null
        tooltip: false,
        triggerType: ['text'],
        onChange: handleChange,
        onCancel,
        onStart,
        onEnd,
        maxLength,
        text: value,
        editing,
      }}
      type={value?.length ? undefined : 'secondary'}
      css={styles.self}
      {...props}
    >
      {value?.length ? value : placeholder}
    </Component>
  );
};

const InlineInput = (props: Omit<BaseProps<TextProps>, 'Component'>) => (
  <BaseInlineInput<TextProps> Component={Typography.Text} {...props} />
);

const InlineInputTitle = (props: Omit<BaseProps<TitleProps>, 'Component'>) => (
  <BaseInlineInput<TitleProps> Component={Typography.Title} {...props} />
);

const InlineInputParagraph = (props: Omit<BaseProps<ParagraphProps>, 'Component'>) => (
  <BaseInlineInput<ParagraphProps> Component={Typography.Paragraph} {...props} />
);

InlineInput.Title = InlineInputTitle;
InlineInput.Paragraph = InlineInputParagraph;

export default InlineInput;

const styles = createStyles({
  self: ({ token }) =>
    css({
      '&:not(.ant-typography-edit-content)': {
        display: 'inline-block',

        '&:hover': {
          paddingInline: token.paddingXXS,
          marginInline: -token.marginXXS,
          outline: `2px solid ${token.colorBorder}`,
          borderRadius: token.borderRadiusOuter,
          cursor: 'text',
        },
      },

      '&.ant-typography-edit-content': {
        width: `calc(100% + ${token.paddingSM}px)`,
      },
    }),
});
