import { useMemo, useCallback } from 'react';
import { type SetStateAction, atom, useAtomValue, useSetAtom } from 'jotai';

type Modal<D> = Record<string, { isVisible: boolean; data?: D }>;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const modalsAtom = atom<Modal<any>>({});

const useModal = <D>(key: string) => {
  const modals = useAtomValue<Modal<D>>(modalsAtom);
  // eslint-disable-next-line @typescript-eslint/no-invalid-void-type
  const setModals = useSetAtom<Modal<D>, [SetStateAction<Modal<D>>], void>(modalsAtom);

  const openModal = useCallback(
    () => setModals({ ...modals, [key]: { isVisible: true } }),
    [setModals, modals, key],
  );

  const openModalWithData = useCallback(
    (data: D) => setModals({ ...modals, [key]: { isVisible: true, data } }),
    [setModals, modals, key],
  );

  const closeModal = useCallback(
    () => setModals({ ...modals, [key]: { isVisible: false, data: undefined } }),
    [setModals, modals, key],
  );

  return useMemo(
    () => ({
      openModal,
      openModalWithData,
      closeModal,
      isModalOpen: !!modals[key]?.isVisible,
      data: modals[key]?.data,
    }),
    [openModal, openModalWithData, closeModal, modals, key],
  );
};

export default useModal;
