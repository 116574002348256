import { get, post, patch, del } from './client';
import { getURL, getAuthorizationHeader } from './utils';
import { SortOrder } from './enums';
import { PAGINATION_DEFAULT_PAGE, PAGINATION_DEFAULT_SIZE } from './constants';
import {
  availabilityResponseSchema,
  namedEntityPagedResponseSchema,
  signInResponseSchema,
  signOutResponseSchema,
  organizationResponseSchema,
  userPermissionsSchema,
  updateUserPermissionsSchema,
  userResponseSchema,
  usersResponseSchema,
  forgottenPasswordResponseSchema,
  setPasswordResponseSchema,
  teamResponseSchema,
  teamsResponseSchema,
  createTeamMembersResponseSchema,
  teamMemberResponseSchema,
  teamMembersResponseSchema,
  reviewResponseSchema,
  reviewValidationResponseSchema,
  reviewsResponseSchema,
  deleteSectionResponseSchema,
  sectionResponseSchema,
  sectionsResponseSchema,
  deleteQuestionResponseSchema,
  questionResponseSchema,
  reviewTemplatesResponseSchema,
  createReviewTeamsResponseSchema,
  reviewTeamResponseSchema,
  reviewTeamsResponseSchema,
  reviewCyclesResponseSchema,
  reviewCycleResponseSchema,
  reviewAssignmentsResponseSchema,
  reviewAssignmentResponseSchema,
  reviewAssignmentOutcomeResponseSchema,
  reviewCycleTeamsResponseSchema,
  reviewCycleTeamResponseSchema,
  observerResponseSchema,
  observersResponseSchema,
  observatoryReviewAssignmentsResponseSchema,
} from './models';
import type {
  PaginationParams,
  SignInPayload,
  CreateOrganizationPayload,
  UpdateOrganizationPayload,
  CreateUserPayload,
  UpdateUserPayload,
  UpdateUserPermissionsPayload,
  ChangePasswordPayload,
  ForgottenPasswordPayload,
  SetPasswordPayload,
  FetchUsersFilters,
  CreateTeamPayload,
  UpdateTeamPayload,
  FetchTeamsFilters,
  CreateTeamMembersPayload,
  FetchTeamMembersFilters,
  CreateReviewPayload,
  UpdateReviewPayload,
  UpdateReviewCycleSettingsPayload,
  CreateSectionPayload,
  UpdateSectionPayload,
  UpdateSectionPositionPayload,
  CreateQuestionPayload,
  UpdateQuestionPayload,
  UpdateQuestionSectionPositionPayload,
  FetchReviewsFilters,
  CreateReviewFromTemplatePayload,
  FetchReviewTemplatesFilters,
  CreateReviewTeamsPayload,
  FetchReviewTeamsFilters,
  FetchReviewCyclesFilters,
  SaveAnswersRequest,
  FetchReviewAssignmentsFilters,
  FetchReviewAssignmentTeamsFilters,
  FetchReviewAssignmentRecipientsFilters,
  FetchReviewAssignmentAuthorsFilters,
  FetchReviewAssignmentsByAuthorFilters,
  FetchReviewAssignmentByAuthorTeamsFilters,
  FetchReviewAssignmentByAuthorRecipientsFilters,
  FetchReviewAssignmentByAuthorReviewsFilters,
  FetchReviewAssignmentsByRecipientFilters,
  FetchReviewAssignmentByRecipientTeamsFilters,
  FetchReviewAssignmentByRecipientAuthorsFilters,
  FetchReviewAssignmentByRecipientReviewsFilters,
  FetchReviewCycleTeamsFilters,
  CreateObserverPayload,
  FetchObserversFilters,
  FetchObservatoryObserversFilters,
  FetchObservatoryReviewAssignmentsFilters,
  FetchObservatoryReviewCyclesFilters,
  FetchObservatoryReviewTeamsFilters,
} from './models';

/**
 * Auth
 */

export const signIn = (body: SignInPayload) =>
  post(signInResponseSchema, getURL('/auth/login'), {
    body,
  });

export const relogin = () =>
  patch(signInResponseSchema, getURL('/auth/renew'), {
    headers: getAuthorizationHeader(),
  });

export const signOut = () =>
  post(signOutResponseSchema, getURL('/auth/logout'), {
    headers: getAuthorizationHeader(),
  });

/**
 * Organization
 */

export const fetchOrganization = (organizationId: string) =>
  get(organizationResponseSchema, getURL(`/organization/${organizationId}`), {
    headers: getAuthorizationHeader(),
  });

export const createOrganization = (body: CreateOrganizationPayload) =>
  post(organizationResponseSchema, getURL('/organization'), {
    body,
  });

export const updateOrganization = (organizationId: string, body: UpdateOrganizationPayload) =>
  patch(organizationResponseSchema, getURL(`/organization/${organizationId}`), {
    headers: getAuthorizationHeader(),
    body,
  });

export const requestRemovalOrganization = (organizationId: string) =>
  patch(organizationResponseSchema, getURL(`/organization/${organizationId}/request-removal`), {
    headers: getAuthorizationHeader(),
  });

export const revokeRemovalOrganization = (organizationId: string) =>
  patch(organizationResponseSchema, getURL(`/organization/${organizationId}/revoke-removal`), {
    headers: getAuthorizationHeader(),
  });

/**
 * User
 */

export const fetchUsers = (
  organizationId: string,
  filters: Partial<FetchUsersFilters>,
  pagination: PaginationParams,
) =>
  get(
    usersResponseSchema,
    getURL(`/organization/${organizationId}/user`, { ...filters, ...pagination }),
    {
      headers: getAuthorizationHeader(),
    },
  );

export const fetchUser = (organizationId: string, userId: string) =>
  get(userResponseSchema, getURL(`/organization/${organizationId}/user/${userId}`), {
    headers: getAuthorizationHeader(),
  });

export const fetchUserPermissions = (organizationId: string, userId: string) =>
  get(userPermissionsSchema, getURL(`/organization/${organizationId}/user/${userId}/permissions`), {
    headers: getAuthorizationHeader(),
  });

export const createUser = (organizationId: string, body: CreateUserPayload) =>
  post(userResponseSchema, getURL(`/organization/${organizationId}/user`), {
    headers: getAuthorizationHeader(),
    body,
  });

export const updateUser = (organizationId: string, userId: string, body: UpdateUserPayload) =>
  patch(userResponseSchema, getURL(`/organization/${organizationId}/user/${userId}`), {
    headers: getAuthorizationHeader(),
    body,
  });

export const activateUser = (organizationId: string, userId: string) =>
  patch(userResponseSchema, getURL(`/organization/${organizationId}/user/${userId}/activate`), {
    headers: getAuthorizationHeader(),
  });

export const deactivateUser = (organizationId: string, userId: string) =>
  patch(userResponseSchema, getURL(`/organization/${organizationId}/user/${userId}/deactivate`), {
    headers: getAuthorizationHeader(),
  });

export const changeUserPermissions = (
  organizationId: string,
  userId: string,
  body: UpdateUserPermissionsPayload,
) =>
  patch(
    updateUserPermissionsSchema,
    getURL(`/organization/${organizationId}/user/${userId}/permissions`),
    {
      headers: getAuthorizationHeader(),
      body,
    },
  );

export const changePassword = (
  organizationId: string,
  userId: string,
  body: ChangePasswordPayload,
) =>
  patch(userResponseSchema, getURL(`/organization/${organizationId}/user/${userId}/password`), {
    headers: getAuthorizationHeader(),
    body,
  });

export const forgottenPassword = ({ email }: ForgottenPasswordPayload) =>
  post(forgottenPasswordResponseSchema, getURL(`/user/forgot-password/${email}`), {
    headers: getAuthorizationHeader(),
  });

export const setPassword = (token: string, body: SetPasswordPayload) =>
  patch(setPasswordResponseSchema, getURL('/user/password'), {
    headers: getAuthorizationHeader(token),
    body,
  });

/**
 * Team
 */

export const fetchTeams = (
  organizationId: string,
  filters: Partial<FetchTeamsFilters>,
  pagination: PaginationParams,
) =>
  get(
    teamsResponseSchema,
    getURL(`/organization/${organizationId}/team`, { ...filters, ...pagination }),
    {
      headers: getAuthorizationHeader(),
    },
  );

export const fetchTeam = (organizationId: string, teamId: string) =>
  get(teamResponseSchema, getURL(`/organization/${organizationId}/team/${teamId}`), {
    headers: getAuthorizationHeader(),
  });

export const createTeam = (organizationId: string, body: CreateTeamPayload) =>
  post(teamResponseSchema, getURL(`/organization/${organizationId}/team`), {
    headers: getAuthorizationHeader(),
    body,
  });

export const updateTeam = (organizationId: string, teamId: string, body: UpdateTeamPayload) =>
  patch(teamResponseSchema, getURL(`/organization/${organizationId}/team/${teamId}`), {
    headers: getAuthorizationHeader(),
    body,
  });

export const activateTeam = (organizationId: string, teamId: string) =>
  patch(teamResponseSchema, getURL(`/organization/${organizationId}/team/${teamId}/activate`), {
    headers: getAuthorizationHeader(),
  });

export const deactivateTeam = (organizationId: string, teamId: string) =>
  patch(teamResponseSchema, getURL(`/organization/${organizationId}/team/${teamId}/deactivate`), {
    headers: getAuthorizationHeader(),
  });

/**
 * Team Member
 */

export const fetchTeamMembers = (
  organizationId: string,
  filters: Partial<FetchTeamMembersFilters>,
  pagination: PaginationParams,
) =>
  get(
    teamMembersResponseSchema,
    getURL(`/organization/${organizationId}/team-member`, { ...filters, ...pagination }),
    {
      headers: getAuthorizationHeader(),
    },
  );

export const createTeamMembers = (organizationId: string, body: CreateTeamMembersPayload) =>
  post(createTeamMembersResponseSchema, getURL(`/organization/${organizationId}/team-member`), {
    headers: getAuthorizationHeader(),
    body,
  });

export const deactivateTeamMember = (organizationId: string, teamMemberId: string) =>
  patch(
    teamMemberResponseSchema,
    getURL(`/organization/${organizationId}/team-member/${teamMemberId}/deactivate`),
    {
      headers: getAuthorizationHeader(),
    },
  );

export const fetchAvailableTeamsForTeamMember = (
  organizationId: string,
  userId: string,
  teamName: string,
  onlyAvailable: boolean,
) =>
  get(
    availabilityResponseSchema,
    getURL(`/organization/${organizationId}/team-member/user/${userId}/teams-availability`, {
      name: teamName.length > 0 ? teamName : undefined,
      'only-available': onlyAvailable,
      sort: `name,${SortOrder.Asc}`,
      page: PAGINATION_DEFAULT_PAGE,
      size: PAGINATION_DEFAULT_SIZE,
    }),
    {
      headers: getAuthorizationHeader(),
    },
  );

export const fetchAvailableUsersForTeamMember = (
  organizationId: string,
  teamId: string,
  userName: string,
  onlyAvailable: boolean,
) =>
  get(
    availabilityResponseSchema,
    getURL(`/organization/${organizationId}/team-member/team/${teamId}/users-availability`, {
      name: userName.length > 0 ? userName : undefined,
      'only-available': onlyAvailable,
      sort: [`name,${SortOrder.Asc}`],
      page: PAGINATION_DEFAULT_PAGE,
      size: PAGINATION_DEFAULT_SIZE,
    }),
    {
      headers: getAuthorizationHeader(),
    },
  );

/**
 * Review
 */

export const fetchReviews = (
  organizationId: string,
  filters: Partial<FetchReviewsFilters>,
  pagination: PaginationParams,
) =>
  get(
    reviewsResponseSchema,
    getURL(`/organization/${organizationId}/review`, { ...filters, ...pagination }),
    {
      headers: getAuthorizationHeader(),
    },
  );

export const fetchReview = (organizationId: string, reviewId: string) =>
  get(reviewResponseSchema, getURL(`/organization/${organizationId}/review/${reviewId}`), {
    headers: getAuthorizationHeader(),
  });

export const fetchReviewValidation = (organizationId: string, reviewId: string) =>
  get(
    reviewValidationResponseSchema,
    getURL(`/organization/${organizationId}/review/${reviewId}/validate-start`),
    {
      headers: getAuthorizationHeader(),
    },
  );

export const createReview = (organizationId: string, body: CreateReviewPayload) =>
  post(reviewResponseSchema, getURL(`/organization/${organizationId}/review`), {
    headers: getAuthorizationHeader(),
    body,
  });

export const updateReview = (organizationId: string, reviewId: string, body: UpdateReviewPayload) =>
  patch(reviewResponseSchema, getURL(`/organization/${organizationId}/review/${reviewId}`), {
    headers: getAuthorizationHeader(),
    body,
  });

export const updateReviewCycleSettings = (
  organizationId: string,
  reviewId: string,
  body: UpdateReviewCycleSettingsPayload,
) =>
  patch(
    reviewResponseSchema,
    getURL(`/organization/${organizationId}/review/${reviewId}/cycle-settings`),
    {
      headers: getAuthorizationHeader(),
      body,
    },
  );

export const activateReview = (organizationId: string, reviewId: string) =>
  patch(
    reviewResponseSchema,
    getURL(`/organization/${organizationId}/review/${reviewId}/activate`),
    {
      headers: getAuthorizationHeader(),
    },
  );

export const deactivateReview = (organizationId: string, reviewId: string) =>
  patch(
    reviewResponseSchema,
    getURL(`/organization/${organizationId}/review/${reviewId}/deactivate`),
    {
      headers: getAuthorizationHeader(),
    },
  );

export const startReview = (organizationId: string, reviewId: string) =>
  patch(reviewResponseSchema, getURL(`/organization/${organizationId}/review/${reviewId}/start`), {
    headers: getAuthorizationHeader(),
  });

export const stopReview = (organizationId: string, reviewId: string) =>
  patch(reviewResponseSchema, getURL(`/organization/${organizationId}/review/${reviewId}/stop`), {
    headers: getAuthorizationHeader(),
  });

export const fetchSections = (organizationId: string, reviewId: string) =>
  get(
    sectionsResponseSchema,
    getURL(`/organization/${organizationId}/review/${reviewId}/section`),
    {
      headers: getAuthorizationHeader(),
    },
  );

export const createSection = (
  organizationId: string,
  reviewId: string,
  body: CreateSectionPayload,
) =>
  post(
    sectionResponseSchema,
    getURL(`/organization/${organizationId}/review/${reviewId}/section`),
    {
      headers: getAuthorizationHeader(),
      body,
    },
  );

export const updateSection = (
  organizationId: string,
  reviewId: string,
  sectionId: string,
  body: UpdateSectionPayload,
) =>
  patch(
    sectionResponseSchema,
    getURL(`/organization/${organizationId}/review/${reviewId}/section/${sectionId}`),
    {
      headers: getAuthorizationHeader(),
      body,
    },
  );

export const updateSectionPosition = (
  organizationId: string,
  reviewId: string,
  sectionId: string,
  body: UpdateSectionPositionPayload,
) =>
  patch(
    sectionResponseSchema,
    getURL(`/organization/${organizationId}/review/${reviewId}/section/${sectionId}/position`),
    {
      headers: getAuthorizationHeader(),
      body,
    },
  );

export const mergeSections = (
  organizationId: string,
  reviewId: string,
  sectionId: string,
  sectionIdToMerge: string,
) =>
  patch(
    sectionResponseSchema,
    getURL(
      `/organization/${organizationId}/review/${reviewId}/section/${sectionId}/merge/${sectionIdToMerge}`,
    ),
    {
      headers: getAuthorizationHeader(),
    },
  );

export const deleteSection = (organizationId: string, reviewId: string, sectionId: string) =>
  del(
    deleteSectionResponseSchema,
    getURL(`/organization/${organizationId}/review/${reviewId}/section/${sectionId}`),
    {
      headers: getAuthorizationHeader(),
    },
  );

export const createQuestion = (
  organizationId: string,
  reviewId: string,
  sectionId: string,
  body: CreateQuestionPayload,
) =>
  post(
    questionResponseSchema,
    getURL(`/organization/${organizationId}/review/${reviewId}/section/${sectionId}/question`),
    {
      headers: getAuthorizationHeader(),
      body,
    },
  );

export const updateQuestion = (
  organizationId: string,
  reviewId: string,
  sectionId: string,
  questionId: string,
  body: UpdateQuestionPayload,
) =>
  patch(
    questionResponseSchema,
    getURL(
      `/organization/${organizationId}/review/${reviewId}/section/${sectionId}/question/${questionId}`,
    ),
    {
      headers: getAuthorizationHeader(),
      body,
    },
  );

export const updateQuestionPosition = (
  organizationId: string,
  reviewId: string,
  sectionId: string,
  questionId: string,
  body: UpdateQuestionSectionPositionPayload,
) =>
  patch(
    questionResponseSchema,
    getURL(
      `/organization/${organizationId}/review/${reviewId}/section/${sectionId}/question/${questionId}/section-position`,
    ),
    {
      headers: getAuthorizationHeader(),
      body,
    },
  );

export const deleteQuestion = (
  organizationId: string,
  reviewId: string,
  sectionId: string,
  questionId: string,
) =>
  del(
    deleteQuestionResponseSchema,
    getURL(
      `/organization/${organizationId}/review/${reviewId}/section/${sectionId}/question/${questionId}`,
    ),
    {
      headers: getAuthorizationHeader(),
    },
  );

/**
 * Review Template
 */

export const fetchReviewTemplates = (
  organizationId: string,
  filters: Partial<FetchReviewTemplatesFilters>,
  pagination: PaginationParams,
) =>
  get(
    reviewTemplatesResponseSchema,
    getURL(`/organization/${organizationId}/review-template`, { ...filters, ...pagination }),
    {
      headers: getAuthorizationHeader(),
    },
  );

export const createReviewFromTemplate = (
  organizationId: string,
  templateId: string,
  body: CreateReviewFromTemplatePayload,
) =>
  post(
    reviewResponseSchema,
    getURL(`/organization/${organizationId}/review-template/${templateId}`),
    {
      headers: getAuthorizationHeader(),
      body,
    },
  );

/**
 * Review Team
 */

export const fetchReviewTeams = (
  organizationId: string,
  filters: Partial<FetchReviewTeamsFilters>,
  pagination: PaginationParams,
) =>
  get(
    reviewTeamsResponseSchema,
    getURL(`/organization/${organizationId}/review-team`, { ...filters, ...pagination }),
    {
      headers: getAuthorizationHeader(),
    },
  );

export const fetchAvailableReviewTeams = (
  organizationId: string,
  filters: Partial<Pick<FetchReviewTeamsFilters, 'review-ids' | 'team-name' | 'exclude-ids'>>,
) =>
  get(
    reviewTeamsResponseSchema,
    getURL(`/organization/${organizationId}/review-team`, {
      ...filters,
      sort: `teamName,${SortOrder.Asc}`,
      page: PAGINATION_DEFAULT_PAGE,
      size: PAGINATION_DEFAULT_SIZE,
    }),
    {
      headers: getAuthorizationHeader(),
    },
  );

export const createReviewTeams = (organizationId: string, body: CreateReviewTeamsPayload) =>
  post(createReviewTeamsResponseSchema, getURL(`/organization/${organizationId}/review-team`), {
    headers: getAuthorizationHeader(),
    body,
  });

export const deactivateReviewTeam = (organizationId: string, reviewTeamId: string) =>
  patch(
    reviewTeamResponseSchema,
    getURL(`/organization/${organizationId}/review-team/${reviewTeamId}/deactivate`),
    {
      headers: getAuthorizationHeader(),
    },
  );

export const fetchAvailableTeamsForReviewTeam = (
  organizationId: string,
  reviewId: string,
  teamName: string,
  onlyAvailable: boolean,
) =>
  get(
    availabilityResponseSchema,
    getURL(`/organization/${organizationId}/review-team/review/${reviewId}/teams-availability`, {
      name: teamName.length > 0 ? teamName : undefined,
      'only-available': onlyAvailable,
      sort: `name,${SortOrder.Asc}`,
      page: PAGINATION_DEFAULT_PAGE,
      size: PAGINATION_DEFAULT_SIZE,
    }),
    {
      headers: getAuthorizationHeader(),
    },
  );

export const fetchAvailableReviewsForReviewTeam = (
  organizationId: string,
  teamId: string,
  reviewName: string,
  onlyAvailable: boolean,
) =>
  get(
    availabilityResponseSchema,
    getURL(`/organization/${organizationId}/review-team/team/${teamId}/reviews-availability`, {
      name: reviewName.length > 0 ? reviewName : undefined,
      'only-available': onlyAvailable,
      sort: `name,${SortOrder.Asc}`,
      page: PAGINATION_DEFAULT_PAGE,
      size: PAGINATION_DEFAULT_SIZE,
    }),
    {
      headers: getAuthorizationHeader(),
    },
  );

/**
 * Review Cycle
 */

export const fetchReviewCycles = (
  organizationId: string,
  reviewId: string,
  filters: Partial<FetchReviewCyclesFilters>,
  pagination: PaginationParams,
) =>
  get(
    reviewCyclesResponseSchema,
    getURL(`/organization/${organizationId}/review/${reviewId}/review-cycle`, {
      ...filters,
      ...pagination,
    }),
    {
      headers: getAuthorizationHeader(),
    },
  );

export const fetchReviewCycle = async (
  organizationId: string,
  reviewId: string,
  reviewCycleId: string,
) => {
  const {
    reviewCycles: [reviewCycle],
  } = await get(
    reviewCyclesResponseSchema,
    getURL(`/organization/${organizationId}/review/${reviewId}/review-cycle`, {
      ids: [reviewCycleId],
      'include-inactive': true,
    }),
    {
      headers: getAuthorizationHeader(),
    },
  );

  if (!reviewCycle) {
    throw new Error('404');
  }

  return { reviewCycle };
};

export const deactivateReviewCycle = (
  organizationId: string,
  reviewId: string,
  reviewCycleId: string,
) =>
  patch(
    reviewCycleResponseSchema,
    getURL(
      `/organization/${organizationId}/review/${reviewId}/review-cycle/${reviewCycleId}/deactivate`,
    ),
    {
      headers: getAuthorizationHeader(),
    },
  );

export const stopReviewCycle = (organizationId: string, reviewId: string, reviewCycleId: string) =>
  patch(
    reviewCycleResponseSchema,
    getURL(`/organization/${organizationId}/review/${reviewId}/review-cycle/${reviewCycleId}/stop`),
    {
      headers: getAuthorizationHeader(),
    },
  );

export const remindReviewCycle = (
  organizationId: string,
  reviewId: string,
  reviewCycleId: string,
) =>
  patch(
    reviewCycleResponseSchema,
    getURL(
      `/organization/${organizationId}/review/${reviewId}/review-cycle/${reviewCycleId}/remind`,
    ),
    {
      headers: getAuthorizationHeader(),
    },
  );

/**
 * Review Cycle Team
 */

export const fetchReviewCycleTeams = (
  organizationId: string,
  reviewId: string,
  reviewCycleId: string,
  filters: Partial<FetchReviewCycleTeamsFilters>,
  pagination: PaginationParams,
) =>
  get(
    reviewCycleTeamsResponseSchema,
    getURL(
      `/organization/${organizationId}/review/${reviewId}/review-cycle/${reviewCycleId}/review-cycle-team`,
      { ...filters, ...pagination },
    ),
    {
      headers: getAuthorizationHeader(),
    },
  );

export const fetchReviewCycleTeam = async (
  organizationId: string,
  reviewId: string,
  reviewCycleId: string,
  reviewCycleTeamId: string,
) => {
  const {
    reviewCycleTeams: [reviewCycleTeam],
  } = await get(
    reviewCycleTeamsResponseSchema,
    getURL(
      `/organization/${organizationId}/review/${reviewId}/review-cycle/${reviewCycleId}/review-cycle-team`,
      {
        ids: [reviewCycleTeamId],
      },
    ),
    {
      headers: getAuthorizationHeader(),
    },
  );

  if (!reviewCycleTeam) {
    throw new Error('404');
  }

  return { reviewCycleTeam };
};

export const deactivateReviewCycleTeam = (
  organizationId: string,
  reviewId: string,
  reviewCycleId: string,
  reviewCycleTeamId: string,
) =>
  patch(
    reviewCycleTeamResponseSchema,
    getURL(
      `/organization/${organizationId}/review/${reviewId}/review-cycle/${reviewCycleId}/review-cycle-team/${reviewCycleTeamId}/deactivate`,
    ),
    {
      headers: getAuthorizationHeader(),
    },
  );

/**
 * Review Assignment
 */

export const fetchReviewAssignments = (
  organizationId: string,
  reviewId: string,
  reviewCycleId: string,
  filters: Partial<FetchReviewAssignmentsFilters>,
  pagination: PaginationParams,
) =>
  get(
    reviewAssignmentsResponseSchema,
    getURL(
      `/organization/${organizationId}/review/${reviewId}/review-cycle/${reviewCycleId}/review-assignment`,
      { ...filters, ...pagination },
    ),
    {
      headers: getAuthorizationHeader(),
    },
  );

export const fetchReviewAssignmentTeams = (
  organizationId: string,
  reviewId: string,
  reviewCycleId: string,
  filters: Partial<FetchReviewAssignmentTeamsFilters>,
) =>
  get(
    namedEntityPagedResponseSchema,
    getURL(
      `/organization/${organizationId}/review/${reviewId}/review-cycle/${reviewCycleId}/review-assignment/team`,
      filters,
    ),
    {
      headers: getAuthorizationHeader(),
    },
  );

export const fetchReviewAssignmentRecipients = (
  organizationId: string,
  reviewId: string,
  reviewCycleId: string,
  filters: Partial<FetchReviewAssignmentRecipientsFilters>,
) =>
  get(
    namedEntityPagedResponseSchema,
    getURL(
      `/organization/${organizationId}/review/${reviewId}/review-cycle/${reviewCycleId}/review-assignment/recipient`,
      filters,
    ),
    {
      headers: getAuthorizationHeader(),
    },
  );

export const fetchReviewAssignmentAuthors = (
  organizationId: string,
  reviewId: string,
  reviewCycleId: string,
  filters: Partial<FetchReviewAssignmentAuthorsFilters>,
) =>
  get(
    namedEntityPagedResponseSchema,
    getURL(
      `/organization/${organizationId}/review/${reviewId}/review-cycle/${reviewCycleId}/review-assignment/author`,
      filters,
    ),
    {
      headers: getAuthorizationHeader(),
    },
  );

export const deactivateReviewAssignment = (
  organizationId: string,
  reviewId: string,
  reviewCycleId: string,
  reviewAssignmentId: string,
) =>
  patch(
    reviewAssignmentResponseSchema,
    getURL(
      `/organization/${organizationId}/review/${reviewId}/review-cycle/${reviewCycleId}/review-assignment/${reviewAssignmentId}/deactivate`,
    ),
    {
      headers: getAuthorizationHeader(),
    },
  );

export const fetchReviewAssignmentsByAuthor = (
  organizationId: string,
  filters: Partial<FetchReviewAssignmentsByAuthorFilters>,
  pagination: PaginationParams,
) =>
  get(
    reviewAssignmentsResponseSchema,
    getURL(`/organization/${organizationId}/review-assignment/by-author`, {
      ...filters,
      ...pagination,
    }),
    {
      headers: getAuthorizationHeader(),
    },
  );

export const fetchReviewAssignmentByAuthorTeams = (
  organizationId: string,
  filters: Partial<FetchReviewAssignmentByAuthorTeamsFilters>,
) =>
  get(
    namedEntityPagedResponseSchema,
    getURL(`/organization/${organizationId}/review-assignment/by-author/team`, filters),
    {
      headers: getAuthorizationHeader(),
    },
  );

export const fetchReviewAssignmentByAuthorRecipients = (
  organizationId: string,
  filters: Partial<FetchReviewAssignmentByAuthorRecipientsFilters>,
) =>
  get(
    namedEntityPagedResponseSchema,
    getURL(`/organization/${organizationId}/review-assignment/by-author/recipient`, filters),
    {
      headers: getAuthorizationHeader(),
    },
  );

export const fetchReviewAssignmentByAuthorReviews = (
  organizationId: string,
  filters: Partial<FetchReviewAssignmentByAuthorReviewsFilters>,
) =>
  get(
    namedEntityPagedResponseSchema,
    getURL(`/organization/${organizationId}/review-assignment/by-author/review`, filters),
    {
      headers: getAuthorizationHeader(),
    },
  );

export const fetchReviewAssignmentsByRecipient = (
  organizationId: string,
  filters: Partial<FetchReviewAssignmentsByRecipientFilters>,
  pagination: PaginationParams,
) =>
  get(
    reviewAssignmentsResponseSchema,
    getURL(`/organization/${organizationId}/review-assignment/by-recipient`, {
      ...filters,
      ...pagination,
    }),
    {
      headers: getAuthorizationHeader(),
    },
  );

export const fetchReviewAssignmentByRecipientTeams = (
  organizationId: string,
  filters: Partial<FetchReviewAssignmentByRecipientTeamsFilters>,
) =>
  get(
    namedEntityPagedResponseSchema,
    getURL(`/organization/${organizationId}/review-assignment/by-recipient/team`, filters),
    {
      headers: getAuthorizationHeader(),
    },
  );

export const fetchReviewAssignmentByRecipientAuthors = (
  organizationId: string,
  filters: Partial<FetchReviewAssignmentByRecipientAuthorsFilters>,
) =>
  get(
    namedEntityPagedResponseSchema,
    getURL(`/organization/${organizationId}/review-assignment/by-recipient/author`, filters),
    {
      headers: getAuthorizationHeader(),
    },
  );

export const fetchReviewAssignmentByRecipientReviews = (
  organizationId: string,
  filters: Partial<FetchReviewAssignmentByRecipientReviewsFilters>,
) =>
  get(
    namedEntityPagedResponseSchema,
    getURL(`/organization/${organizationId}/review-assignment/by-recipient/review`, filters),
    {
      headers: getAuthorizationHeader(),
    },
  );

export const fetchReviewAssignmentSectionsQuestions = (
  organizationId: string,
  reviewAssignmentId: string,
) =>
  get(
    sectionsResponseSchema,
    getURL(
      `/organization/${organizationId}/review-assignment/${reviewAssignmentId}/sections-questions`,
    ),
    {
      headers: getAuthorizationHeader(),
    },
  );

export const openReviewAssignment = (organizationId: string, reviewAssignmentId: string) =>
  patch(
    reviewAssignmentOutcomeResponseSchema,
    getURL(`/organization/${organizationId}/review-assignment/${reviewAssignmentId}/open`),
    {
      headers: getAuthorizationHeader(),
    },
  );

export const saveReviewAssignment = (
  organizationId: string,
  reviewAssignmentId: string,
  body: SaveAnswersRequest,
) =>
  patch(
    reviewAssignmentOutcomeResponseSchema,
    getURL(`/organization/${organizationId}/review-assignment/${reviewAssignmentId}/save`),
    {
      headers: getAuthorizationHeader(),
      body,
    },
  );

export const completeReviewAssignment = (
  organizationId: string,
  reviewAssignmentId: string,
  body: SaveAnswersRequest,
) =>
  patch(
    reviewAssignmentOutcomeResponseSchema,
    getURL(`/organization/${organizationId}/review-assignment/${reviewAssignmentId}/complete`),
    {
      headers: getAuthorizationHeader(),
      body,
    },
  );

export const collectReviewAssignment = (organizationId: string, reviewAssignmentId: string) =>
  patch(
    reviewAssignmentOutcomeResponseSchema,
    getURL(`/organization/${organizationId}/review-assignment/${reviewAssignmentId}/collect`),
    {
      headers: getAuthorizationHeader(),
    },
  );

/**
 * Observer
 */

export const fetchObservers = (
  organizationId: string,
  filters: Partial<FetchObserversFilters>,
  pagination: PaginationParams,
) =>
  get(
    observersResponseSchema,
    getURL(`/organization/${organizationId}/observer`, { ...filters, ...pagination }),
    {
      headers: getAuthorizationHeader(),
    },
  );

export const createObserver = (organizationId: string, body: CreateObserverPayload) =>
  post(observerResponseSchema, getURL(`/organization/${organizationId}/observer`), {
    headers: getAuthorizationHeader(),
    body,
  });

export const deactivateObserver = (organizationId: string, observerId: string) =>
  patch(
    observerResponseSchema,
    getURL(`/organization/${organizationId}/observer/${observerId}/deactivate`),
    {
      headers: getAuthorizationHeader(),
    },
  );

export const fetchAvailableReviewsForObserver = (
  organizationId: string,
  userId: string,
  reviewName: string,
  onlyAvailable: boolean,
) =>
  get(
    availabilityResponseSchema,
    getURL(`/organization/${organizationId}/observer/user/${userId}/reviews-availability`, {
      name: reviewName.length > 0 ? reviewName : undefined,
      'only-available': onlyAvailable,
      sort: `name,${SortOrder.Asc}`,
      page: PAGINATION_DEFAULT_PAGE,
      size: PAGINATION_DEFAULT_SIZE,
    }),
    {
      headers: getAuthorizationHeader(),
    },
  );

export const fetchAvailableUsersForObserver = (
  organizationId: string,
  reviewId: string,
  userName: string,
  onlyAvailable: boolean,
) =>
  get(
    availabilityResponseSchema,
    getURL(`/organization/${organizationId}/observer/review/${reviewId}/users-availability`, {
      name: userName.length > 0 ? userName : undefined,
      'only-available': onlyAvailable,
      sort: [`name,${SortOrder.Asc}`],
      page: PAGINATION_DEFAULT_PAGE,
      size: PAGINATION_DEFAULT_SIZE,
    }),
    {
      headers: getAuthorizationHeader(),
    },
  );

/**
 * Observatory
 */

export const fetchObservatoryObservers = (
  organizationId: string,
  filters: Partial<FetchObservatoryObserversFilters>,
  pagination: PaginationParams,
) =>
  get(
    observersResponseSchema,
    getURL(`/organization/${organizationId}/observatory/observer`, { ...filters, ...pagination }),
    {
      headers: getAuthorizationHeader(),
    },
  );

export const fetchObservatoryReviewAssignments = (
  organizationId: string,
  reviewId: string,
  filters: Partial<FetchObservatoryReviewAssignmentsFilters>,
  pagination: PaginationParams,
) =>
  get(
    observatoryReviewAssignmentsResponseSchema,
    getURL(`/organization/${organizationId}/observatory/review/${reviewId}`, {
      ...filters,
      ...pagination,
    }),
    {
      headers: getAuthorizationHeader(),
    },
  );

export const fetchObservatoryReviewCycles = (
  organizationId: string,
  reviewId: string,
  filters: Partial<FetchObservatoryReviewCyclesFilters>,
  pagination: PaginationParams,
) =>
  get(
    reviewCyclesResponseSchema,
    getURL(`/organization/${organizationId}/observatory/review/${reviewId}/review-cycle`, {
      ...filters,
      ...pagination,
    }),
    {
      headers: getAuthorizationHeader(),
    },
  );

export const fetchObservatoryReviewTeams = (
  organizationId: string,
  reviewId: string,
  filters: Partial<FetchObservatoryReviewTeamsFilters>,
  pagination: PaginationParams,
) =>
  get(
    reviewTeamsResponseSchema,
    getURL(`/organization/${organizationId}/observatory/review/${reviewId}/review-team`, {
      ...filters,
      ...pagination,
    }),
    {
      headers: getAuthorizationHeader(),
    },
  );

export const fetchObservatoryReviewSections = (organizationId: string, reviewId: string) =>
  get(
    sectionsResponseSchema,
    getURL(`/organization/${organizationId}/observatory/review/${reviewId}/section`),
    {
      headers: getAuthorizationHeader(),
    },
  );
