import { Tag, type TagProps } from 'antd';
import { css } from '@emotion/react';

import { createStyles } from '@/common/styleUtils';

export type Props = TagProps;

const Pill = (props: Props) => (
  <Tag css={[styles.self, props.onClick && styles.clickable]} {...props} />
);

export default Pill;

const styles = createStyles({
  self: ({ token }) =>
    css({
      margin: 0,
      borderRadius: 99,
      fontSize: 13,
      paddingInline: token.paddingXS,

      '.ant-select-item-option-content &': {
        display: 'inline-flex',
        fontSize: token.fontSize,
        lineHeight: `${Math.round(token.fontSize * token.lineHeight)}px`,
      },

      '.ant-select-selection-item &': {
        display: 'inline-flex',
        lineHeight: '20px',
      },
    }),

  clickable: css({
    cursor: 'pointer',
  }),
});
