import { useMemo, useCallback, useState } from 'react';
import { useAtomValue, useSetAtom } from 'jotai';
import { useQueryClient } from '@tanstack/react-query';

import { type SignInPayload, UserPermission } from '@/api';
import {
  signIn as signInApi,
  relogin as reloginApi,
  signOut as signOutApi,
  fetchUserPermissions,
} from '@/api/endpoints';
import { store } from '@/app/providers/Jotai';

import {
  organizationIdAtom,
  userIdAtom,
  tokenAtom,
  isLoggedInAtom,
  useSetAuthState,
  useResetAuthState,
  userPermissionsAtom,
  useResetUserPermissionsAtom,
} from './state';

export { UserPermission } from '@/api';

export { default as RequireIsAnonymous } from './RequireIsAnonymous';
export { default as RequireIsLoggedIn } from './RequireIsLoggedIn';
export { default as RequirePermissions } from './RequirePermissions';
export { default as ForbidPermissions } from './ForbidPermissions';

export const getToken = () => store.get(tokenAtom);

export const useAuth = () => {
  const organizationId = useAtomValue(organizationIdAtom);
  const userId = useAtomValue(userIdAtom);
  const token = useAtomValue(tokenAtom);
  const isLoggedIn = useAtomValue(isLoggedInAtom);

  return useMemo(
    () => ({ organizationId, userId, token, isLoggedIn }),
    [organizationId, userId, token, isLoggedIn],
  );
};

export const useSignIn = () => {
  const [isPending, setIsPending] = useState(false);

  const setAuthState = useSetAuthState();

  const setUserPermissions = useSetAtom(userPermissionsAtom);

  const signIn = useCallback(
    async (payload: SignInPayload, opts?: { onError?: (error: unknown) => void }) => {
      try {
        setIsPending(true);

        const auth = await signInApi(payload);
        setAuthState(auth);

        const { permissions } = await fetchUserPermissions(auth.organizationId, auth.userId);
        setUserPermissions(permissions);
      } catch (error: unknown) {
        opts?.onError?.(error);
      } finally {
        setIsPending(false);
      }
    },
    [setAuthState, setIsPending, setUserPermissions],
  );

  return useMemo(() => ({ isPending, signIn }), [isPending, signIn]);
};

export const useRelogin = () => {
  const [isPending, setIsPending] = useState(false);

  const setAuthState = useSetAuthState();
  const resetAuthState = useResetAuthState();

  const setUserPermissions = useSetAtom(userPermissionsAtom);
  const resetUserPermissions = useResetUserPermissionsAtom();

  const relogin = useCallback(async () => {
    try {
      setIsPending(true);

      const auth = await reloginApi();
      setAuthState(auth);

      const { permissions } = await fetchUserPermissions(auth.organizationId, auth.userId);
      setUserPermissions(permissions);
    } catch {
      resetAuthState();
      resetUserPermissions();
    } finally {
      setIsPending(false);
    }
  }, [setAuthState, resetAuthState, setIsPending, setUserPermissions, resetUserPermissions]);

  return useMemo(() => ({ isPending, relogin }), [isPending, relogin]);
};

export const useSignOut = () => {
  const [isPending, setIsPending] = useState(false);

  const resetAuthState = useResetAuthState();

  const resetUserPermissions = useResetUserPermissionsAtom();

  const queryClient = useQueryClient();

  const signOut = useCallback(async () => {
    try {
      setIsPending(true);
      await signOutApi();
    } finally {
      resetAuthState();
      resetUserPermissions();
      queryClient.removeQueries();
      setIsPending(false);
    }
  }, [resetAuthState, resetUserPermissions, setIsPending, queryClient]);

  return useMemo(() => ({ isPending, signOut }), [isPending, signOut]);
};

export const usePermissions = () => {
  const userPermissions = useAtomValue(userPermissionsAtom);

  return useMemo(
    () => ({
      canManageOrganization: userPermissions.includes(UserPermission.ManageOrganization),
      canManageUsers: userPermissions.includes(UserPermission.ManageUsers),
      canManageTeams: userPermissions.includes(UserPermission.ManageTeams),
      canManageReviews: userPermissions.includes(UserPermission.ManageReviews),
      hasAnyPermissions: (permissions: readonly UserPermission[]) =>
        permissions.some((p) => userPermissions.includes(p)),
      userPermissions,
    }),
    [userPermissions],
  );
};
