import { type ReactNode } from 'react';
import { Button, type ButtonProps } from 'antd';

import { useConfirmation } from '@/common/hooks';

type Props = ButtonProps & {
  confirmationContent: ReactNode;
  onClick: VoidFunction;
};

const TableActionButton = ({ confirmationContent, onClick, ...props }: Props) => (
  <Button
    type="primary"
    size="small"
    onClick={useConfirmation({ content: confirmationContent }, onClick)}
    {...props}
  />
);

export default TableActionButton;
