import { Select as SelectOrig, type SelectProps, type RefSelectProps } from 'antd';

import { useAutoFocus } from '@/common/hooks';

const Select = ({ autoFocus, ...props }: SelectProps) => {
  const ref = useAutoFocus<RefSelectProps>();

  return (
    <SelectOrig
      // Workaround for autoFocus not working in Modal
      ref={autoFocus ? ref : undefined}
      // Prevent Enter key from submitting the form
      onKeyUp={(e) => (e.key === 'Enter' ? e.stopPropagation() : undefined)}
      {...props}
    />
  );
};

export default Select;
