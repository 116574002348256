export const AUTH_ROUTES = {
  index: {
    path: 'auth/*',
    to: '/auth',
  },
  signUp: {
    path: 'sign-up',
    to: '/auth/sign-up',
  },
  forgottenPassword: {
    path: 'forgotten-password',
    to: '/auth/forgotten-password',
  },
  signIn: {
    path: 'sign-in',
    to: '/auth/sign-in',
  },
  activateUser: {
    path: 'activate-user',
    to: '/auth/activate-user',
  },
  resetPassword: {
    path: 'reset-password',
    to: '/auth/reset-password',
  },
} as const;
