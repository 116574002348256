import { useCallback, type ReactNode, Children, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Form, Row, Col } from 'antd';
import { DownOutlined, UpOutlined } from '@ant-design/icons';
import { css } from '@emotion/react';
import { Collapse } from 'react-collapse';

import { type Filters } from '@/common/hooks/usePaginationFilters';
import { createStyles, getMQ, useBreakpoint } from '@/common/styleUtils';
import { SpaceVertical } from '@/common/components';
import { useOnMount } from '@/common/hooks';

type Props<F> = {
  initialValues: F;
  onChange: (filters: F) => void;
  onClear?: VoidFunction;
  children: ReactNode;
};

const TableFilters = <F extends Filters>({
  initialValues,
  onChange,
  onClear,
  children,
}: Props<F>) => {
  const { t } = useTranslation();

  const sm = useBreakpoint('sm');

  const [form] = Form.useForm<F>();

  useOnMount(() => {
    for (const [key, value] of Object.entries(initialValues)) {
      form.setFieldValue(key, value); // TODO use form.setFieldsValue
    }
  });

  const handleClear = useCallback(() => {
    form.resetFields();
    onClear?.();
  }, [form, onClear]);

  const handleValuesChange = useCallback((_: unknown, values: F) => onChange(values), [onChange]);

  const [isCollapsed, setIsCollapsed] = useState(!sm);

  return (
    <SpaceVertical>
      {!sm && (
        <Button
          type="text"
          block
          icon={isCollapsed ? <DownOutlined /> : <UpOutlined />}
          onClick={() => setIsCollapsed((c) => !c)}
        >
          {isCollapsed ? t('common:table.filters.show') : t('common:table.filters.hide')}
        </Button>
      )}
      <Collapse isOpened={!isCollapsed || !!sm}>
        <Form<F> form={form} onValuesChange={handleValuesChange} css={styles.self}>
          <Row gutter={[8, 8]}>
            {Children.map(children, (child) => (
              <Col>{child}</Col>
            ))}
            {onClear && (
              <Col flex="1">
                <Button onClick={handleClear} block={!sm}>
                  {t('common:table.filters.clear')}
                </Button>
              </Col>
            )}
          </Row>
        </Form>
      </Collapse>
    </SpaceVertical>
  );
};

TableFilters.checkboxNormalize = (v: boolean) => (v === true ? v : undefined);

export default TableFilters;

const styles = createStyles({
  self: ({ token }) =>
    css({
      '.ant-form-item': {
        marginBottom: 0,
      },

      '.ant-select, .ant-checkbox-wrapper': {
        minWidth: 196,
      },

      [getMQ(token).xsMax]: {
        '> .ant-row > .ant-col': {
          flex: '100%',
        },
      },
    }),
});
