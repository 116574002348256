import qs from 'query-string';

import { getToken } from '@/common/auth';

export const getURL = (path: string, params?: Record<string, unknown>) =>
  import.meta.env.VITE_API_URL +
  path +
  (params && Object.keys(params).length > 0 ? `?${qs.stringify(params)}` : '');

export const getAuthorizationHeader = (token?: string) => {
  const t = token ?? getToken();
  return t ? { Authorization: `Bearer ${t}` } : undefined;
};

// AntDesign pagination begins from 1 while API pagination begins from 0
export const toApiPage = (page: number) => page - 1;
export const fromApiPage = (page: number) => page + 1;
