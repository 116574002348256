/**
 * Common
 */

export enum ErrorCode {
  ANSWER_CANNOT_BE_EMPTY = 'answer.cannot.be.empty',
  ANSWER_INVALID_OPTION_VALUE_FOR_QUESTION_TYPE = 'answer.invalid.option.value.for.question.type',
  ANSWER_INVALID_VALUE_FOR_DATE_QUESTION_TYPE = 'answer.invalid.value.for.date.question.type',
  ANSWER_INVALID_VALUE_FOR_FIVE_STARS_QUESTION_TYPE = 'answer.invalid.value.for.five.stars.question.type',
  ANSWER_INVALID_VALUE_FOR_HAPPINESS_QUESTION_TYPE = 'answer.invalid.value.for.happiness.question.type',
  ANSWER_INVALID_VALUE_FOR_TIME_QUESTION_TYPE = 'answer.invalid.value.for.time.question.type',
  ANSWER_INVALID_VALUE_FOR_TRUE_FALSE_QUESTION_TYPE = 'answer.invalid.value.for.true.false.question.type',
  ANSWER_SINGLE_VALUE_REQUIRED = 'answer.single.value.required',
  AUTHENTICATION_ERROR = 'authentication.error',
  AUTHORIZATION_ERROR = 'authorization.error',
  CREDENTIALS_ERROR = 'credentials.error',
  EMAIL_GLOBALLY_UNIQUE_ACROSS_ORGANIZATIONS = 'email.globally.unique.across.organizations',
  ENTITY_COUNT_LIMIT_REACHED = 'entity.count.limit.reached',
  ENTITY_DEACTIVATED = 'entity.deactivated',
  ENTITY_DOES_NOT_BELONG_TO_ORGANIZATION = 'entity.does.not.belong.to.organization',
  ENTITY_NOT_FOUND = 'entity.not.found',
  INTERNAL_SERVER_ERROR = 'internal.server.error',
  NOTIFICATIONS_EMAIL_ERROR = 'notifications.email.error',
  OBSERVER_REVIEW_TEAM_DOES_NOT_BELONG_TO_OBSERVER = 'observer.review.team.does.not.belong.to.observer',
  OBSERVER_USER_ALREADY_OBSERVES_REVIEW = 'observer.user.already.observes.review',
  OBSERVER_USER_DOES_NOT_OBSERVE_REVIEW = 'observer.user.does.not.observe.review',
  OBSERVER_USER_DOES_NOT_OBSERVE_REVIEW_TEAM = 'observer.user.does.not.observe.review.team',
  OBSERVER_USER_OBSERVES_ALL_REVIEW_TEAMS = 'observer.user.observes.all.review.teams',
  OBSERVER_USER_OBSERVES_MAX_50_REVIEW_TEAMS = 'observer.user.observes.max.50.review.teams',
  OBSERVER_USER_OBSERVES_THE_REVIEW_TEAM = 'observer.user.observes.the.review.team',
  PATH_NOT_FOUND = 'path.not.found',
  QUESTION_ANSWER_VISIBILITY_UNSUPPORTED_FOR_REVIEW = 'question.answer.visibility.unsupported.for.review',
  QUESTION_DOES_NOT_BELONG_TO_REVIEW = 'question.does.not.belong.to.review',
  QUESTION_DOES_NOT_BELONG_TO_SECTION = 'question.does.not.belong.to.section',
  QUESTION_LIMIT_PER_REVIEW = 'question.limit.per.review',
  QUESTION_OPTIONS_INVALID_POSITION = 'question.options.invalid.position',
  QUESTION_OPTIONS_NON_UNIQUE_VALUE = 'question.options.non.unique.value',
  QUESTION_OPTIONS_NOT_REQUIRED = 'question.options.not.required',
  QUESTION_OPTIONS_REQUIRED = 'question.options.required',
  QUESTION_POSITION_GREATER_THAN_MAX_POSITION = 'question.position.greater.than.max.position',
  QUESTION_TYPE_UNSUPPORTED_FOR_REVIEW = 'question.type.unsupported.for.review',
  REQUEST_PROCESSING_ERROR = 'request.processing.error',
  REVIEW_ASSIGNMENT_CANNOT_BE_COMPLETED_MISSING_ANSWERS_FOR_REQUIRED_QUESTIONS = 'review.assignment.cannot.be.completed.missing.answers.for.required.questions',
  REVIEW_ASSIGNMENT_DOES_NOT_BELONG_TO_AUTHOR_USER = 'review.assignment.does.not.belong.to.author.user',
  REVIEW_ASSIGNMENT_DOES_NOT_BELONG_TO_RECIPIENT_USER = 'review.assignment.does.not.belong.to.recipient.user',
  REVIEW_ASSIGNMENT_DOES_NOT_BELONG_TO_REVIEW_CYCLE = 'review.assignment.does.not.belong.to.review.cycle',
  REVIEW_ASSIGNMENT_DOES_NOT_BELONG_TO_REVIEW_CYCLE_TEAM = 'review.assignment.does.not.belong.to.review.cycle.team',
  REVIEW_ASSIGNMENT_DOES_NOT_BELONG_TO_USER = 'review.assignment.does.not.belong.to.user',
  REVIEW_ASSIGNMENT_HAS_BEEN_PUBLISHED = 'review.assignment.has.been.published',
  REVIEW_ASSIGNMENT_HAS_NOT_BEEN_PUBLISHED = 'review.assignment.has.not.been.published',
  REVIEW_CYCLE_DOES_NOT_BELONG_TO_REVIEW = 'review.cycle.does.not.belong.to.review',
  REVIEW_CYCLE_OPERATION_ALLOWED_ONLY_ON_IN_PROGRESS_STATUS = 'review.cycle.operation.allowed.only.on.in.progress.status',
  REVIEW_CYCLE_TEAM_DOES_NOT_BELONG_TO_REVIEW_CYCLE = 'review.cycle.team.does.not.belong.to.review.cycle',
  REVIEW_DAYS_OF_WEEK_IS_ONLY_VALID_FOR_WEEKLY_FREQUENCY = 'review.days.of.week.is.only.valid.for.weekly.frequency',
  REVIEW_DAYS_OF_WEEK_MUST_BE_SELECTED_FOR_WEEKLY_FREQUENCY = 'review.days.of.week.must.be.selected.for.weekly.frequency',
  REVIEW_DURATION_LESS_THAN_MIN = 'review.duration.less.than.min',
  REVIEW_DURATION_MORE_THAN_MAX = 'review.duration.more.than.max',
  REVIEW_DURATION_SMALLEST_UNIT_HOUR = 'review.duration.smallest.unit.hour',
  REVIEW_FIRST_CYCLE_AT_DATE_MUST_BE_IN_FUTURE = 'review.first.cycle.at.date.must.be.in.future',
  REVIEW_FIRST_CYCLE_AT_START_CANNOT_HAVE_FIRST_CYCLE_AT_DATE_SET = 'review.first.cycle.at.start.cannot.have.first.cycle.at.date.set',
  REVIEW_IN_PROGRESS_FIELD_CANNOT_BE_UPDATED = 'review.in.progress.field.cannot.be.updated',
  REVIEW_INVALID_RECURRING_SETTINGS = 'review.invalid.recurring.settings',
  REVIEW_MISSING_OBSERVERS = 'review.missing.observers',
  REVIEW_MISSING_QUESTIONS = 'review.missing.questions',
  REVIEW_MISSING_TEAM = 'review.missing.team',
  REVIEW_NAME_TAKEN = 'review.name.taken',
  REVIEW_ONE_CYCLE_TERMINATION_METHOD_MUST_BE_SELECTED = 'review.one.cycle.termination.method.must.be.selected',
  REVIEW_RECURRING_COUNT_LESS_THAN_OCCURRENCES = 'review.recurring.count.less.than.occurrences',
  REVIEW_REMIND_INCOMPLETE_DURATION_MUST_BE_LESS_THAN_DURATION = 'review.remind.incomplete.duration.must.be.less.than.duration',
  REVIEW_REMIND_INCOMPLETE_DURATION_SMALLEST_UNIT_HOUR = 'review.remind.incomplete.duration.smallest.unit.hour',
  REVIEW_TEAM_RELATION_ALREADY_ACTIVE_FOR_REVIEW = 'review.team.relation.already.active.for.review',
  REVIEW_TEAM_RELATION_DOES_NOT_BELONG_TO_REVIEW = 'review.team.relation.does.not.belong.to.review',
  REVIEW_TEMPLATE_NOT_FOUND = 'review.template.not.found',
  REVIEW_UNTIL_DATE_MUST_BE_AFTER_FIRST_CYCLE_DATE = 'review.until.date.must.be.after.first.cycle.date',
  REVIEW_UNTIL_DATE_MUST_BE_IN_FUTURE = 'review.until.date.must.be.in.future',
  SECTION_DOES_NOT_BELONG_TO_REVIEW = 'section.does.not.belong.to.review',
  SECTION_LIMIT_PER_REVIEW = 'section.limit.per.review',
  SECTION_POSITION_GREATER_THAN_MAX_POSITION = 'section.position.greater.than.max.position',
  SORT_INVALID_DIRECTION = 'sort.invalid.direction',
  SORT_INVALID_FIELD = 'sort.invalid.field',
  SORT_INVALID_FORMAT = 'sort.invalid.format',
  SUBSCRIPTION_TIER_ENTERPRISE_EXPIRATION_NOT_SET = 'subscription.tier.enterprise.expiration.not.set',
  SUBSCRIPTION_TIER_MULTIPLE_ACTIVE = 'subscription.tier.multiple.active',
  TEAM_MEMBER_USER_ALREADY_ACTIVE_IN_TEAM = 'team.member.user.already.active.in.team',
  TEAM_NAME_TAKEN = 'team.name.taken',
  TOKEN_ERROR = 'token.error',
  TOKEN_EXPIRED = 'token.expired',
  TOKEN_INVALID_ACTION_USAGE = 'token.invalid.action.usage',
  TOKEN_NOT_FOUND = 'token.not.found',
  TOKEN_NOT_PROVIDED = 'token.not.provided',
  TOKEN_NOT_RENEWABLE = 'token.not.renewable',
  TOO_MANY_REQUESTS = 'too.many.requests',
  TYPE_MISMATCH_ERROR = 'type.mismatch.error',
  USER_CANNOT_EDIT_SELF = 'user.cannot.edit.self',
  USER_EMAIL_TAKEN = 'user.email.taken',
  USER_PASSWORD_DOES_NOT_MATCH = 'user.password.does.not.match',
  USER_PASSWORD_HAS_NOT_BEEN_SET = 'user.password.has.not.been.set',
  USER_PASSWORD_HAS_NOT_CHANGED = 'user.password.has.not.changed',
  VALIDATION_ANSWER_VALUE_ERROR = 'validation.answer.value.error',
  VALIDATION_DESCRIPTION_ERROR = 'validation.description.error',
  VALIDATION_EMAIL_ERROR = 'validation.email.error',
  VALIDATION_ENTITY_ID_ERROR = 'validation.entity.id.error',
  VALIDATION_ERROR = 'validation.error',
  VALIDATION_FIRSTNAME_ERROR = 'validation.firstname.error',
  VALIDATION_LASTNAME_ERROR = 'validation.lastname.error',
  VALIDATION_ORGANIZATION_NAME_ERROR = 'validation.organization.name.error',
  VALIDATION_ORGANIZATION_NAME_FORMAT = 'validation.organization.name.format',
  VALIDATION_PASSWORD_ERROR = 'validation.password.error',
  VALIDATION_PASSWORD_FORMAT = 'validation.password.format',
  VALIDATION_PHONE_NUMBER_ERROR = 'validation.phone.number.error',
  VALIDATION_PHONE_NUMBER_FORMAT = 'validation.phone.number.format',
  VALIDATION_QUESTION_OPTION_VALUE_ERROR = 'validation.question.option.value.error',
  VALIDATION_QUESTION_TEXT_ERROR = 'validation.question.text.error',
  VALIDATION_REVIEW_NAME_ERROR = 'validation.review.name.error',
  VALIDATION_TEAM_NAME_ERROR = 'validation.team.name.error',
  VALIDATION_TEXT_ERROR = 'validation.text.error',
  VALIDATION_TIME_TRUNCATED_TO_MINUTES = 'validation.time.truncated.to.minutes',
}

export enum SortOrder {
  Asc = 'ASC',
  Desc = 'DESC',
}

/**
 * Organization
 */

export enum SubscriptionTierType {
  Free = 'FREE',
  Enterprise = 'ENTERPRISE',
}

/**
 * User
 */

export enum UserPermission {
  ManageOrganization = 'ORGANIZATION',
  ManageUsers = 'USERS',
  ManageTeams = 'TEAMS',
  ManageReviews = 'REVIEWS',
}

/**
 * Team
 */

export enum TeamMemberRole {
  Manager = 'MANAGER',
  Member = 'MEMBER',
}

/**
 * Review
 */

export enum ReviewCycleStatus {
  InProgress = 'IN_PROGRESS',
  Done = 'DONE',
}

export enum ReviewAssignmentStatus {
  Assigned = 'ASSIGNED',
  Opened = 'OPENED',
  Completed = 'COMPLETED',
  Unfinished = 'UNFINISHED',
  Published = 'PUBLISHED',
  Collected = 'COLLECTED',
}

export enum AnswerVisibility {
  Shared = 'SHARED',
  Private = 'PRIVATE',
}

export enum QuestionType {
  Text = 'TEXT',
  Date = 'DATE',
  Time = 'TIME',
  TrueFalse = 'TRUE_FALSE',
  Happiness = 'HAPPINESS',
  FiveStars = 'FIVE_STARS',
  Checkboxes = 'CHECKBOXES',
  MultipleChoice = 'MULTIPLE_CHOICE',
}

export enum TrueFalseValue {
  True = 'TRUE',
  False = 'FALSE',
}

export enum ReviewType {
  TopDown = 'TOP_DOWN',
  BottomUp = 'BOTTOM_UP',
  Questionnaire = 'QUESTIONNAIRE',
}

export enum ReviewStatus {
  OnHold = 'ON_HOLD',
  InProgress = 'IN_PROGRESS',
}

export enum ReviewFrequency {
  Daily = 'DAILY',
  Weekly = 'WEEKLY',
  Monthly = 'MONTHLY',
  Yearly = 'YEARLY',
}

export enum StopRecurring {
  Never = 'NEVER',
  After = 'AFTER',
  On = 'ON',
}

export enum FirstReviewCycle {
  AtStart = 'AT_START',
  AtFutureDate = 'AT_FUTURE_DATE',
}

export enum WeekDay {
  Monday = 'MONDAY',
  Tuesday = 'TUESDAY',
  Wednesday = 'WEDNESDAY',
  Thursday = 'THURSDAY',
  Friday = 'FRIDAY',
  Saturday = 'SATURDAY',
  Sunday = 'SUNDAY',
}

export enum Sentiment {
  Positive = 'POSITIVE',
  Neutral = 'NEUTRAL',
  Negative = 'NEGATIVE',
  Unknown = 'UNKNOWN',
}
