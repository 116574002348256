import { useTranslation } from 'react-i18next';

import { SubscriptionTierType } from '@/api';
import { Pill } from '@/common/components';

type Props = {
  type: SubscriptionTierType;
};

const TierType = ({ type }: Props) => {
  const { t } = useTranslation();

  switch (type) {
    case SubscriptionTierType.Free: {
      return <>{t('common:tierType.free')}</>;
    }
    case SubscriptionTierType.Enterprise: {
      return <>{t('common:tierType.enterprise')}</>;
    }
  }
};

const typeColors = {
  [SubscriptionTierType.Free]: 'geekblue',
  [SubscriptionTierType.Enterprise]: 'gold',
} satisfies Record<SubscriptionTierType, string>;

TierType.Tag = function TierTypeTag(props: Props) {
  return (
    <Pill color={typeColors[props.type]}>
      <TierType {...props} />
    </Pill>
  );
};

export default TierType;
