import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Table as TableOrig, type TableProps } from 'antd';

import { fromApiPage } from '@/api';

import TableFilters from './TableFilters';
import TableActionButton from './TableActionButton';

export type TableSortOrder = Required<TableProps<object>>['sortDirections'][number];

type Props<RecordType> = TableProps<RecordType> & {
  onSortChange?: (field?: string, order?: TableSortOrder) => void;
  showSizeChanger?: boolean;
};

const Table = <RecordType extends object>({
  onSortChange,
  showSizeChanger = false,
  rowKey = 'id',
  pagination,
  onChange,
  ...props
}: Props<RecordType>) => {
  const { t } = useTranslation();

  const handleChange = useCallback<Required<TableProps<RecordType>>['onChange']>(
    (pagination, filters, sorter, extra) => {
      if (onSortChange && extra.action === 'sort') {
        const { field, order } = Array.isArray(sorter) ? sorter[0] ?? {} : sorter;
        onSortChange(String(field), order);
      }

      onChange?.(pagination, filters, sorter, extra);
    },
    [onSortChange, onChange],
  );

  const paginationConfig = useMemo(
    () =>
      pagination
        ? {
            showTotal: (total: number, [from, to]: [number, number]) =>
              t('common:table.paginationShowTotal', { from, to, total }),
            showSizeChanger,
            ...pagination,
            current: pagination.current === undefined ? undefined : fromApiPage(pagination.current),
          }
        : pagination,
    [t, pagination, showSizeChanger],
  );

  return (
    <TableOrig<RecordType>
      scroll={{ x: true }}
      {...props}
      rowKey={rowKey}
      pagination={paginationConfig}
      onChange={handleChange}
    />
  );
};

Table.Filters = TableFilters;
Table.ActionButton = TableActionButton;
Table.Column = TableOrig.Column;
Table.ColumnGroup = TableOrig.ColumnGroup;
Table.Summary = TableOrig.Summary;

export default Table;
