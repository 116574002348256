import { useQuery, useMutation, useQueryClient, keepPreviousData } from '@tanstack/react-query';

import { AVAILABILITY_MAX_LENGTH, AVAILABILITY_MIN_LENGTH } from './constants';
import {
  fetchOrganization,
  createOrganization,
  updateOrganization,
  requestRemovalOrganization,
  revokeRemovalOrganization,
  fetchUsers,
  fetchUser,
  fetchUserPermissions,
  createUser,
  updateUser,
  activateUser,
  deactivateUser,
  changeUserPermissions,
  changePassword,
  forgottenPassword,
  setPassword,
  fetchTeams,
  fetchTeam,
  createTeam,
  updateTeam,
  activateTeam,
  deactivateTeam,
  fetchTeamMembers,
  createTeamMembers,
  deactivateTeamMember,
  fetchReviews,
  fetchReview,
  fetchReviewValidation,
  createReview,
  updateReview,
  updateReviewCycleSettings,
  activateReview,
  deactivateReview,
  startReview,
  stopReview,
  fetchSections,
  createSection,
  updateSection,
  updateSectionPosition,
  mergeSections,
  deleteSection,
  createQuestion,
  updateQuestion,
  updateQuestionPosition,
  deleteQuestion,
  fetchReviewTemplates,
  createReviewFromTemplate,
  fetchReviewTeams,
  createReviewTeams,
  deactivateReviewTeam,
  fetchAvailableReviewTeams,
  fetchReviewCycles,
  fetchReviewCycle,
  deactivateReviewCycle,
  stopReviewCycle,
  remindReviewCycle,
  fetchReviewCycleTeam,
  deactivateReviewCycleTeam,
  fetchReviewAssignmentTeams,
  fetchReviewAssignmentAuthors,
  deactivateReviewAssignment,
  fetchReviewAssignmentsByAuthor,
  openReviewAssignment,
  fetchReviewAssignmentByAuthorTeams,
  fetchReviewAssignmentByAuthorRecipients,
  fetchReviewAssignmentByAuthorReviews,
  fetchReviewAssignmentsByRecipient,
  fetchReviewAssignmentByRecipientTeams,
  fetchReviewAssignmentByRecipientAuthors,
  fetchReviewAssignmentByRecipientReviews,
  collectReviewAssignment,
  fetchReviewAssignmentSectionsQuestions,
  saveReviewAssignment,
  completeReviewAssignment,
  fetchObservers,
  createObserver,
  deactivateObserver,
  fetchAvailableReviewsForObserver,
  fetchAvailableUsersForObserver,
  fetchReviewAssignmentRecipients,
  fetchReviewAssignments,
  fetchReviewCycleTeams,
  fetchAvailableReviewsForReviewTeam,
  fetchAvailableTeamsForReviewTeam,
  fetchAvailableUsersForTeamMember,
  fetchAvailableTeamsForTeamMember,
  fetchObservatoryObservers,
  fetchObservatoryReviewAssignments,
  fetchObservatoryReviewCycles,
  fetchObservatoryReviewTeams,
  fetchObservatoryReviewSections,
} from './endpoints';
import type {
  PaginationParams,
  UpdateOrganizationPayload,
  CreateUserPayload,
  UpdateUserPayload,
  UpdateUserPermissionsPayload,
  ChangePasswordPayload,
  ForgottenPasswordPayload,
  SetPasswordPayload,
  FetchUsersFilters,
  CreateTeamPayload,
  UpdateTeamPayload,
  FetchTeamsFilters,
  CreateTeamMembersPayload,
  FetchTeamMembersFilters,
  ReviewResponse,
  CreateReviewPayload,
  UpdateReviewPayload,
  UpdateReviewCycleSettingsPayload,
  CreateSectionPayload,
  UpdateSectionPayload,
  UpdateSectionPositionPayload,
  CreateQuestionPayload,
  UpdateQuestionPayload,
  UpdateQuestionSectionPositionPayload,
  FetchReviewsFilters,
  CreateReviewFromTemplatePayload,
  FetchReviewTemplatesFilters,
  CreateReviewTeamsPayload,
  FetchReviewTeamsFilters,
  FetchReviewCyclesFilters,
  SaveAnswersRequest,
  FetchReviewAssignmentsFilters,
  FetchReviewAssignmentTeamsFilters,
  FetchReviewAssignmentRecipientsFilters,
  FetchReviewAssignmentAuthorsFilters,
  FetchReviewAssignmentsByAuthorFilters,
  FetchReviewAssignmentByAuthorTeamsFilters,
  FetchReviewAssignmentByAuthorRecipientsFilters,
  FetchReviewAssignmentByAuthorReviewsFilters,
  FetchReviewAssignmentsByRecipientFilters,
  FetchReviewAssignmentByRecipientTeamsFilters,
  FetchReviewAssignmentByRecipientAuthorsFilters,
  FetchReviewAssignmentByRecipientReviewsFilters,
  FetchReviewCycleTeamsFilters,
  CreateObserverPayload,
  FetchObserversFilters,
  FetchObservatoryObserversFilters,
  FetchObservatoryReviewAssignmentsFilters,
  FetchObservatoryReviewCyclesFilters,
  FetchObservatoryReviewTeamsFilters,
} from './models';
import { useCallback } from 'react';

/**
 * Organization
 */

const organizationQueryKeys = {
  all: ['organization'] as const,
  organization: (organizationId: string) => [...organizationQueryKeys.all, organizationId] as const,
};

export const useFetchOrganization = (organizationId: string) =>
  useQuery({
    queryKey: organizationQueryKeys.organization(organizationId),
    queryFn: () => fetchOrganization(organizationId),
  });

export const useCreateOrganization = () =>
  useMutation({
    mutationFn: createOrganization,
  });

export const useUpdateOrganization = (organizationId: string) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (payload: UpdateOrganizationPayload) => updateOrganization(organizationId, payload),
    onSuccess: (data) => {
      queryClient.setQueryData(organizationQueryKeys.organization(data.organization.id), data);
    },
  });
};

export const useRequestRemovalOrganization = (organizationId: string) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: () => requestRemovalOrganization(organizationId),
    onSuccess: (data) => {
      queryClient.setQueryData(organizationQueryKeys.organization(data.organization.id), data);
    },
  });
};

export const useRevokeRemovalOrganization = (organizationId: string) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: () => revokeRemovalOrganization(organizationId),
    onSuccess: (data) => {
      queryClient.setQueryData(organizationQueryKeys.organization(data.organization.id), data);
    },
  });
};

/**
 * User
 */

const userQueryKeys = {
  all: (organizationId: string) => ['organization', organizationId, 'user'] as const,
  list: (
    organizationId: string,
    filters: Partial<FetchUsersFilters> = {},
    pagination: Partial<PaginationParams> = {},
  ) => [...userQueryKeys.all(organizationId), filters, pagination] as const,
  user: (organizationId: string, userId: string) =>
    [...userQueryKeys.all(organizationId), userId] as const,
  userPermissions: (organizationId: string, userId: string) =>
    [...userQueryKeys.all(organizationId), userId, 'permissions'] as const,
};

export const useFetchUsers = (
  organizationId: string,
  filters: Partial<FetchUsersFilters>,
  pagination: PaginationParams,
) =>
  useQuery({
    queryKey: userQueryKeys.list(organizationId, filters, pagination),
    queryFn: () => fetchUsers(organizationId, filters, pagination),
    placeholderData: keepPreviousData,
  });

export const useFetchUser = (organizationId: string, userId: string) =>
  useQuery({
    queryKey: userQueryKeys.user(organizationId, userId),
    queryFn: () => fetchUser(organizationId, userId),
  });

export const useFetchUserPermissions = (organizationId: string, userId: string) =>
  useQuery({
    queryKey: userQueryKeys.userPermissions(organizationId, userId),
    queryFn: () => fetchUserPermissions(organizationId, userId),
  });

export const useCreateUser = (organizationId: string) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (payload: CreateUserPayload) => createUser(organizationId, payload),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: userQueryKeys.list(organizationId) });
    },
  });
};

export const useUpdateUser = (organizationId: string) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ userId, payload }: { userId: string; payload: UpdateUserPayload }) =>
      updateUser(organizationId, userId, payload),
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: userQueryKeys.list(organizationId) });
      queryClient.setQueryData(userQueryKeys.user(organizationId, data.user.id), data);
    },
  });
};

export const useActivateUser = (organizationId: string) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (userId: string) => activateUser(organizationId, userId),
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: userQueryKeys.list(organizationId) });
      queryClient.setQueryData(userQueryKeys.user(organizationId, data.user.id), data);
    },
  });
};

export const useDeactivateUser = (organizationId: string) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (userId: string) => deactivateUser(organizationId, userId),
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: userQueryKeys.list(organizationId) });
      queryClient.setQueryData(userQueryKeys.user(organizationId, data.user.id), data);
      queryClient.invalidateQueries({ queryKey: reviewQueryKeys.all(organizationId) });
    },
  });
};

export const useChangeUserPermissions = (organizationId: string) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ userId, payload }: { userId: string; payload: UpdateUserPermissionsPayload }) =>
      changeUserPermissions(organizationId, userId, payload),
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries({ queryKey: userQueryKeys.list(organizationId) });
      queryClient.setQueryData(
        userQueryKeys.userPermissions(organizationId, variables.userId),
        data,
      );
    },
  });
};

export const useChangePassword = (organizationId: string) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ userId, payload }: { userId: string; payload: ChangePasswordPayload }) =>
      changePassword(organizationId, userId, payload),
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: userQueryKeys.list(organizationId) });
      queryClient.setQueryData(userQueryKeys.user(organizationId, data.user.id), data);
    },
  });
};

export const useForgottenPassword = () =>
  useMutation({
    mutationFn: (payload: ForgottenPasswordPayload) => forgottenPassword(payload),
  });

export const useSetPassword = () =>
  useMutation({
    mutationFn: ({ token, payload }: { token: string; payload: SetPasswordPayload }) =>
      setPassword(token, payload),
  });

/**
 * Team
 */

const teamQueryKeys = {
  all: (organizationId: string) => ['organization', organizationId, 'team'] as const,
  list: (
    organizationId: string,
    filters: Partial<FetchTeamsFilters> = {},
    pagination: Partial<PaginationParams> = {},
  ) => [...teamQueryKeys.all(organizationId), filters, pagination] as const,
  team: (organizationId: string, teamId: string) =>
    [...teamQueryKeys.all(organizationId), teamId] as const,
};

export const useFetchTeams = (
  organizationId: string,
  filters: Partial<FetchTeamsFilters>,
  pagination: PaginationParams,
) =>
  useQuery({
    queryKey: teamQueryKeys.list(organizationId, filters, pagination),
    queryFn: () => fetchTeams(organizationId, filters, pagination),
    placeholderData: keepPreviousData,
  });

export const useFetchTeam = (organizationId: string, teamId: string) =>
  useQuery({
    queryKey: teamQueryKeys.team(organizationId, teamId),
    queryFn: () => fetchTeam(organizationId, teamId),
  });

export const useCreateTeam = (organizationId: string) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (payload: CreateTeamPayload) => createTeam(organizationId, payload),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: teamQueryKeys.list(organizationId) });
    },
  });
};

export const useUpdateTeam = (organizationId: string) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ teamId, payload }: { teamId: string; payload: UpdateTeamPayload }) =>
      updateTeam(organizationId, teamId, payload),
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: teamQueryKeys.list(organizationId) });
      queryClient.setQueryData(teamQueryKeys.team(organizationId, data.team.id), data);
    },
  });
};

export const useActivateTeam = (organizationId: string) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (teamId: string) => activateTeam(organizationId, teamId),
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: teamQueryKeys.list(organizationId) });
      queryClient.setQueryData(teamQueryKeys.team(organizationId, data.team.id), data);
    },
  });
};

export const useDeactivateTeam = (organizationId: string) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (teamId: string) => deactivateTeam(organizationId, teamId),
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: teamQueryKeys.list(organizationId) });
      queryClient.setQueryData(teamQueryKeys.team(organizationId, data.team.id), data);
      queryClient.invalidateQueries({ queryKey: reviewQueryKeys.all(organizationId) });
    },
  });
};

/**
 * Team Member
 */

const teamMemberQueryKeys = {
  all: (organizationId: string) => ['organization', organizationId, 'teamMember'] as const,
  list: (
    organizationId: string,
    filters: Partial<FetchTeamMembersFilters> = {},
    pagination: Partial<PaginationParams> = {},
  ) => [...teamMemberQueryKeys.all(organizationId), filters, pagination] as const,
  availableTeamsForTeamMember: (organizationId: string, userId: string, teamName: string) =>
    [
      ...teamMemberQueryKeys.all(organizationId),
      'user',
      userId,
      'teams-availability',
      teamName,
    ] as const,
  availableUsersForTeamMember: (organizationId: string, teamId: string, userName: string) =>
    [
      ...teamMemberQueryKeys.all(organizationId),
      'team',
      teamId,
      'users-availability',
      userName,
    ] as const,
};

export const useFetchTeamMembers = (
  organizationId: string,
  filters: Partial<FetchTeamMembersFilters>,
  pagination: PaginationParams,
) =>
  useQuery({
    queryKey: teamMemberQueryKeys.list(organizationId, filters, pagination),
    queryFn: () => fetchTeamMembers(organizationId, filters, pagination),
    placeholderData: keepPreviousData,
  });

export const useCreateTeamMembers = (organizationId: string) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (payload: CreateTeamMembersPayload) => createTeamMembers(organizationId, payload),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: teamMemberQueryKeys.list(organizationId) });
    },
  });
};

export const useDeactivateTeamMember = (organizationId: string) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (teamMemberId: string) => deactivateTeamMember(organizationId, teamMemberId),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: teamMemberQueryKeys.list(organizationId) });
    },
  });
};

export const useFetchAvailableTeamsForTeamMember = (
  organizationId: string,
  userId: string,
  teamName: string,
) =>
  useQuery({
    queryKey: teamMemberQueryKeys.availableTeamsForTeamMember(organizationId, userId, teamName),
    queryFn: () =>
      fetchAvailableTeamsForTeamMember(organizationId, userId, teamName, teamName.length === 0),
    enabled: teamName.length <= AVAILABILITY_MAX_LENGTH,
    initialData: {
      data: [],
      totalItems: 0,
    },
  });

export const useFetchAvailableUsersForTeamMember = (
  organizationId: string,
  teamId: string,
  userName: string,
) =>
  useQuery({
    queryKey: teamMemberQueryKeys.availableUsersForTeamMember(organizationId, teamId, userName),
    queryFn: () =>
      fetchAvailableUsersForTeamMember(organizationId, teamId, userName, userName.length === 0),
    enabled: userName.length <= AVAILABILITY_MAX_LENGTH,
    initialData: {
      data: [],
      totalItems: 0,
    },
  });

/**
 * Review
 */

const reviewQueryKeys = {
  all: (organizationId: string) => ['organization', organizationId, 'review'] as const,
  list: (
    organizationId: string,
    filters: Partial<FetchReviewsFilters> = {},
    pagination: Partial<PaginationParams> = {},
  ) => [...reviewQueryKeys.all(organizationId), filters, pagination] as const,
  review: (organizationId: string, reviewId: string) =>
    [...reviewQueryKeys.all(organizationId), reviewId] as const,
  reviewValidation: (organizationId: string, reviewId: string) =>
    [...reviewQueryKeys.all(organizationId), reviewId, 'validation'] as const,
  reviewSectionList: (organizationId: string, reviewId: string) =>
    [...reviewQueryKeys.review(organizationId, reviewId), 'section'] as const,
};

export const reviewMutationKeys = {
  mutateQuestions: ['mutateQuestions'] as const,
};

export const useInvalidateReview = (organizationId: string) => {
  const queryClient = useQueryClient();

  return useCallback(
    (
      opts:
        | { id: undefined }
        | { id: string; data?: ReviewResponse }
        | { id: string; data?: ReviewResponse; sectionList: true },
    ) => {
      const { id } = opts;
      const data = 'data' in opts ? opts.data : undefined;
      const sectionList = 'sectionList' in opts;

      queryClient.invalidateQueries({ queryKey: reviewQueryKeys.list(organizationId) });

      if (id) {
        if (data) {
          queryClient.setQueryData(reviewQueryKeys.review(organizationId, id), data);
        } else {
          queryClient.invalidateQueries({ queryKey: reviewQueryKeys.review(organizationId, id) });
        }

        queryClient.invalidateQueries({
          queryKey: reviewQueryKeys.reviewValidation(organizationId, id),
        });

        if (sectionList) {
          queryClient.invalidateQueries({
            queryKey: reviewQueryKeys.reviewSectionList(organizationId, id),
          });
        }
      }
    },
    [queryClient, organizationId],
  );
};

export const useFetchReviews = (
  organizationId: string,
  filters: Partial<FetchReviewsFilters>,
  pagination: PaginationParams,
) =>
  useQuery({
    queryKey: reviewQueryKeys.list(organizationId, filters, pagination),
    queryFn: () => fetchReviews(organizationId, filters, pagination),
    placeholderData: keepPreviousData,
  });

export const useFetchReview = (organizationId: string, reviewId: string) =>
  useQuery({
    queryKey: reviewQueryKeys.review(organizationId, reviewId),
    queryFn: () => fetchReview(organizationId, reviewId),
  });

export const useFetchReviewValidation = (organizationId: string, reviewId: string) =>
  useQuery({
    queryKey: reviewQueryKeys.reviewValidation(organizationId, reviewId),
    queryFn: () => fetchReviewValidation(organizationId, reviewId),
  });

export const useCreateReview = (organizationId: string) => {
  const invalidateReview = useInvalidateReview(organizationId);

  return useMutation({
    mutationFn: (payload: CreateReviewPayload) => createReview(organizationId, payload),
    onSuccess: () => {
      invalidateReview({ id: undefined });
    },
  });
};

export const useUpdateReview = (organizationId: string) => {
  const invalidateReview = useInvalidateReview(organizationId);

  return useMutation({
    mutationFn: ({ reviewId, payload }: { reviewId: string; payload: UpdateReviewPayload }) =>
      updateReview(organizationId, reviewId, payload),
    onSuccess: (data) => {
      invalidateReview({ id: data.review.id, data });
    },
  });
};

export const useUpdateReviewCycleSettings = (organizationId: string) => {
  const invalidateReview = useInvalidateReview(organizationId);

  return useMutation({
    mutationFn: ({
      reviewId,
      payload,
    }: {
      reviewId: string;
      payload: UpdateReviewCycleSettingsPayload;
    }) => updateReviewCycleSettings(organizationId, reviewId, payload),
    onSuccess: (data) => {
      invalidateReview({ id: data.review.id, data });
    },
  });
};

export const useActivateReview = (organizationId: string) => {
  const invalidateReview = useInvalidateReview(organizationId);

  return useMutation({
    mutationFn: (reviewId: string) => activateReview(organizationId, reviewId),
    onSuccess: (data) => {
      invalidateReview({ id: data.review.id, data });
    },
  });
};

export const useDeactivateReview = (organizationId: string) => {
  const invalidateReview = useInvalidateReview(organizationId);

  return useMutation({
    mutationFn: (reviewId: string) => deactivateReview(organizationId, reviewId),
    onSuccess: (data) => {
      invalidateReview({ id: data.review.id, data });
    },
  });
};

export const useStartReview = (organizationId: string) => {
  const invalidateReview = useInvalidateReview(organizationId);

  return useMutation({
    mutationFn: (reviewId: string) => startReview(organizationId, reviewId),
    onSuccess: (data) => {
      invalidateReview({ id: data.review.id, data });
    },
  });
};

export const useStopReview = (organizationId: string) => {
  const invalidateReview = useInvalidateReview(organizationId);

  return useMutation({
    mutationFn: (reviewId: string) => stopReview(organizationId, reviewId),
    onSuccess: (data) => {
      invalidateReview({ id: data.review.id, data });
    },
  });
};

export const useFetchSections = (organizationId: string, reviewId: string) =>
  useQuery({
    queryKey: reviewQueryKeys.reviewSectionList(organizationId, reviewId),
    queryFn: () => fetchSections(organizationId, reviewId),
  });

export const useCreateSection = (organizationId: string) => {
  const invalidateReview = useInvalidateReview(organizationId);

  return useMutation({
    mutationKey: reviewMutationKeys.mutateQuestions,
    mutationFn: ({ reviewId, payload }: { reviewId: string; payload: CreateSectionPayload }) =>
      createSection(organizationId, reviewId, payload),
    onSuccess: (_, { reviewId }) => {
      invalidateReview({ id: reviewId, sectionList: true });
    },
  });
};

export const useUpdateSection = (organizationId: string) => {
  const invalidateReview = useInvalidateReview(organizationId);

  return useMutation({
    mutationKey: reviewMutationKeys.mutateQuestions,
    mutationFn: ({
      reviewId,
      sectionId,
      payload,
    }: {
      reviewId: string;
      sectionId: string;
      payload: UpdateSectionPayload;
    }) => updateSection(organizationId, reviewId, sectionId, payload),
    onSuccess: (_, { reviewId }) => {
      invalidateReview({ id: reviewId, sectionList: true });
    },
  });
};

export const useUpdateSectionPosition = (organizationId: string) => {
  const invalidateReview = useInvalidateReview(organizationId);

  return useMutation({
    mutationKey: reviewMutationKeys.mutateQuestions,
    mutationFn: ({
      reviewId,
      sectionId,
      payload,
    }: {
      reviewId: string;
      sectionId: string;
      payload: UpdateSectionPositionPayload;
    }) => updateSectionPosition(organizationId, reviewId, sectionId, payload),
    onSuccess: (_, { reviewId }) => {
      invalidateReview({ id: reviewId, sectionList: true });
    },
  });
};

// TODO unused?
export const useMergeSections = (organizationId: string) => {
  const invalidateReview = useInvalidateReview(organizationId);

  return useMutation({
    mutationKey: reviewMutationKeys.mutateQuestions,
    mutationFn: ({
      reviewId,
      sectionId,
      sectionIdToMerge,
    }: {
      reviewId: string;
      sectionId: string;
      sectionIdToMerge: string;
    }) => mergeSections(organizationId, reviewId, sectionId, sectionIdToMerge),
    onSuccess: (_, { reviewId }) => {
      invalidateReview({ id: reviewId, sectionList: true });
    },
  });
};

export const useDeleteSection = (organizationId: string) => {
  const invalidateReview = useInvalidateReview(organizationId);

  return useMutation({
    mutationKey: reviewMutationKeys.mutateQuestions,
    mutationFn: ({ reviewId, sectionId }: { reviewId: string; sectionId: string }) =>
      deleteSection(organizationId, reviewId, sectionId),
    onSuccess: (_, { reviewId }) => {
      invalidateReview({ id: reviewId, sectionList: true });
    },
  });
};

export const useCreateQuestion = (organizationId: string) => {
  const invalidateReview = useInvalidateReview(organizationId);

  return useMutation({
    mutationKey: reviewMutationKeys.mutateQuestions,
    mutationFn: ({
      reviewId,
      sectionId,
      payload,
    }: {
      reviewId: string;
      sectionId: string;
      payload: CreateQuestionPayload;
    }) => createQuestion(organizationId, reviewId, sectionId, payload),
    onSuccess: (_, { reviewId }) => {
      invalidateReview({ id: reviewId, sectionList: true });
    },
  });
};

export const useUpdateQuestion = (organizationId: string) => {
  const invalidateReview = useInvalidateReview(organizationId);

  return useMutation({
    mutationKey: reviewMutationKeys.mutateQuestions,
    mutationFn: ({
      reviewId,
      sectionId,
      questionId,
      payload,
    }: {
      reviewId: string;
      sectionId: string;
      questionId: string;
      payload: UpdateQuestionPayload;
    }) => updateQuestion(organizationId, reviewId, sectionId, questionId, payload),
    onSuccess: (_, { reviewId }) => {
      invalidateReview({ id: reviewId, sectionList: true });
    },
  });
};

export const useUpdateQuestionPosition = (organizationId: string) => {
  const invalidateReview = useInvalidateReview(organizationId);

  return useMutation({
    mutationKey: reviewMutationKeys.mutateQuestions,
    mutationFn: ({
      reviewId,
      sectionId,
      questionId,
      payload,
    }: {
      reviewId: string;
      sectionId: string;
      questionId: string;
      payload: UpdateQuestionSectionPositionPayload;
    }) => updateQuestionPosition(organizationId, reviewId, sectionId, questionId, payload),
    onSuccess: (_, { reviewId }) => {
      invalidateReview({ id: reviewId, sectionList: true });
    },
  });
};

export const useDeleteQuestion = (organizationId: string) => {
  const invalidateReview = useInvalidateReview(organizationId);

  return useMutation({
    mutationKey: reviewMutationKeys.mutateQuestions,
    mutationFn: ({
      reviewId,
      sectionId,
      questionId,
    }: {
      reviewId: string;
      sectionId: string;
      questionId: string;
    }) => deleteQuestion(organizationId, reviewId, sectionId, questionId),
    onSuccess: (_, { reviewId }) => {
      invalidateReview({ id: reviewId, sectionList: true });
    },
  });
};

/**
 * Review Template
 */

const reviewTemplateQueryKeys = {
  all: (organizationId: string) => ['organization', organizationId, 'reviewTemplate'] as const,
  list: (
    organizationId: string,
    filters: Partial<FetchReviewTemplatesFilters> = {},
    pagination: Partial<PaginationParams> = {},
  ) => [...reviewTeamQueryKeys.all(organizationId), filters, pagination] as const,
};

export const useFetchReviewTemplates = (
  organizationId: string,
  filters: Partial<FetchReviewTemplatesFilters>,
  pagination: PaginationParams,
) =>
  useQuery({
    queryKey: reviewTemplateQueryKeys.list(organizationId, filters, pagination),
    queryFn: () => fetchReviewTemplates(organizationId, filters, pagination),
    placeholderData: keepPreviousData,
  });

export const useCreateReviewFromTemplate = (organizationId: string) => {
  const invalidateReview = useInvalidateReview(organizationId);

  return useMutation({
    mutationFn: ({
      templateId,
      payload,
    }: {
      templateId: string;
      payload: CreateReviewFromTemplatePayload;
    }) => createReviewFromTemplate(organizationId, templateId, payload),
    onSuccess: () => {
      invalidateReview({ id: undefined });
    },
  });
};

/**
 * Review Team
 */

const reviewTeamQueryKeys = {
  all: (organizationId: string) => ['organization', organizationId, 'reviewTeam'] as const,
  list: (
    organizationId: string,
    filters: Partial<FetchReviewTeamsFilters> = {},
    pagination: Partial<PaginationParams> = {},
  ) => [...reviewTeamQueryKeys.all(organizationId), filters, pagination] as const,
  availableReviewTeams: (
    organizationId: string,
    filters: Partial<
      Pick<FetchReviewTeamsFilters, 'review-ids' | 'team-name' | 'exclude-ids'>
    > = {},
  ) => [...reviewTeamQueryKeys.all(organizationId), 'reviewTeams-availability', filters] as const,
  availableTeamsForReviewTeam: (organizationId: string, reviewId: string, teamName: string) =>
    [
      ...reviewTeamQueryKeys.all(organizationId),
      'review',
      reviewId,
      'teams-availability',
      teamName,
    ] as const,
  availableReviewsForReviewTeam: (organizationId: string, teamId: string, reviewName: string) =>
    [
      ...reviewTeamQueryKeys.all(organizationId),
      'team',
      teamId,
      'reviews-availability',
      reviewName,
    ] as const,
};

export const useFetchReviewTeams = (
  organizationId: string,
  filters: Partial<FetchReviewTeamsFilters>,
  pagination: PaginationParams,
) =>
  useQuery({
    queryKey: reviewTeamQueryKeys.list(organizationId, filters, pagination),
    queryFn: () => fetchReviewTeams(organizationId, filters, pagination),
    placeholderData: keepPreviousData,
  });

export const useCreateReviewTeams = (organizationId: string) => {
  const queryClient = useQueryClient();
  const invalidateReview = useInvalidateReview(organizationId);

  return useMutation({
    mutationFn: (payload: CreateReviewTeamsPayload) => createReviewTeams(organizationId, payload),
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: reviewTeamQueryKeys.list(organizationId) });

      for (const reviewTeam of data.reviewTeams) {
        invalidateReview({ id: reviewTeam.review.id });
      }
    },
  });
};

export const useDeactivateReviewTeam = (organizationId: string) => {
  const queryClient = useQueryClient();
  const invalidateReview = useInvalidateReview(organizationId);

  return useMutation({
    mutationFn: (reviewTeamId: string) => deactivateReviewTeam(organizationId, reviewTeamId),
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: reviewTeamQueryKeys.list(organizationId) });

      invalidateReview({ id: data.reviewTeam.review.id });
    },
  });
};

export const useFetchAvailableReviewTeams = (
  organizationId: string,
  reviewId: string,
  teamName?: string,
  excludeReviewTeamIds?: string[],
) => {
  const filters = {
    'review-ids': [reviewId],
    'team-name': teamName,
    'exclude-ids': excludeReviewTeamIds,
  };

  return useQuery({
    queryKey: reviewTeamQueryKeys.availableReviewTeams(organizationId, filters),
    queryFn: () => fetchAvailableReviewTeams(organizationId, filters),
    enabled:
      reviewId !== '' &&
      (teamName === undefined ||
        (teamName.length >= AVAILABILITY_MIN_LENGTH && teamName.length <= AVAILABILITY_MAX_LENGTH)),
    initialData: {
      reviewTeams: [],
      totalItems: 0,
    },
  });
};

export const useFetchAvailableTeamsForReviewTeam = (
  organizationId: string,
  reviewId: string,
  teamName: string,
) =>
  useQuery({
    queryKey: reviewTeamQueryKeys.availableTeamsForReviewTeam(organizationId, reviewId, teamName),
    queryFn: () =>
      fetchAvailableTeamsForReviewTeam(organizationId, reviewId, teamName, teamName.length === 0),
    enabled: teamName.length <= AVAILABILITY_MAX_LENGTH,
    initialData: {
      data: [],
      totalItems: 0,
    },
  });

export const useFetchAvailableReviewsForReviewTeam = (
  organizationId: string,
  teamId: string,
  reviewName: string,
) =>
  useQuery({
    queryKey: reviewTeamQueryKeys.availableReviewsForReviewTeam(organizationId, teamId, reviewName),
    queryFn: () =>
      fetchAvailableReviewsForReviewTeam(
        organizationId,
        teamId,
        reviewName,
        reviewName.length === 0,
      ),
    enabled: reviewName.length <= AVAILABILITY_MAX_LENGTH,
    initialData: {
      data: [],
      totalItems: 0,
    },
  });

/**
 * Review Cycle
 */

const reviewCycleQueryKeys = {
  all: (organizationId: string, reviewId: string) =>
    ['organization', organizationId, 'review', reviewId, 'reviewCycle'] as const,
  list: (
    organizationId: string,
    reviewId: string,
    filters: Partial<FetchReviewCyclesFilters> = {},
    pagination: Partial<PaginationParams> = {},
  ) => [...reviewCycleQueryKeys.all(organizationId, reviewId), filters, pagination] as const,
  reviewCycle: (organizationId: string, reviewId: string, reviewCycleId: string) => [
    ...reviewCycleQueryKeys.all(organizationId, reviewId),
    reviewCycleId,
  ],
};

export const useFetchReviewCycles = (
  organizationId: string,
  reviewId: string,
  filters: Partial<FetchReviewCyclesFilters>,
  pagination: PaginationParams,
) =>
  useQuery({
    queryKey: reviewCycleQueryKeys.list(organizationId, reviewId, filters, pagination),
    queryFn: () => fetchReviewCycles(organizationId, reviewId, filters, pagination),
    placeholderData: keepPreviousData,
  });

export const useFetchReviewCycle = (
  organizationId: string,
  reviewId: string,
  reviewCycleId: string,
) =>
  useQuery({
    queryKey: reviewCycleQueryKeys.reviewCycle(organizationId, reviewId, reviewCycleId),
    queryFn: () => fetchReviewCycle(organizationId, reviewId, reviewCycleId),
  });

export const useDeactivateReviewCycle = (organizationId: string, reviewId: string) => {
  const queryClient = useQueryClient();
  const invalidateReview = useInvalidateReview(organizationId);

  return useMutation({
    mutationFn: (reviewCycleId: string) =>
      deactivateReviewCycle(organizationId, reviewId, reviewCycleId),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: reviewCycleQueryKeys.list(organizationId, reviewId),
      });

      invalidateReview({ id: reviewId });
    },
  });
};

export const useStopReviewCycle = (organizationId: string, reviewId: string) => {
  const queryClient = useQueryClient();
  const invalidateReview = useInvalidateReview(organizationId);

  return useMutation({
    mutationFn: (reviewCycleId: string) => stopReviewCycle(organizationId, reviewId, reviewCycleId),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: reviewCycleQueryKeys.list(organizationId, reviewId),
      });

      invalidateReview({ id: reviewId });
    },
  });
};

export const useRemindReviewCycle = (organizationId: string, reviewId: string) => {
  const queryClient = useQueryClient();
  const invalidateReview = useInvalidateReview(organizationId);

  return useMutation({
    mutationFn: (reviewCycleId: string) =>
      remindReviewCycle(organizationId, reviewId, reviewCycleId),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: reviewCycleQueryKeys.list(organizationId, reviewId),
      });

      invalidateReview({ id: reviewId });
    },
  });
};

/**
 * Review Cycle Team
 */

const reviewCycleTeamQueryKeys = {
  all: (organizationId: string, reviewId: string, reviewCycleId: string) =>
    [
      'organization',
      organizationId,
      'review',
      reviewId,
      'reviewCycle',
      reviewCycleId,
      'reviewCycleTeam',
    ] as const,
  list: (
    organizationId: string,
    reviewId: string,
    reviewCycleId: string,
    filters: Partial<FetchReviewCycleTeamsFilters> = {},
    pagination: Partial<PaginationParams> = {},
  ) =>
    [
      ...reviewCycleTeamQueryKeys.all(organizationId, reviewId, reviewCycleId),
      filters,
      pagination,
    ] as const,
  reviewCycleTeam: (
    organizationId: string,
    reviewId: string,
    reviewCycleId: string,
    reviewCycleTeamId: string,
  ) =>
    [
      ...reviewCycleTeamQueryKeys.all(organizationId, reviewId, reviewCycleId),
      reviewCycleTeamId,
    ] as const,
};

// TODO unused?
export const useFetchReviewCycleTeams = (
  organizationId: string,
  reviewId: string,
  reviewCycleId: string,
  filters: Partial<FetchReviewCycleTeamsFilters>,
  pagination: PaginationParams,
) =>
  useQuery({
    queryKey: reviewCycleTeamQueryKeys.list(
      organizationId,
      reviewId,
      reviewCycleId,
      filters,
      pagination,
    ),
    queryFn: () =>
      fetchReviewCycleTeams(organizationId, reviewId, reviewCycleId, filters, pagination),
    placeholderData: keepPreviousData,
  });

// TODO unused?
export const useFetchReviewCycleTeam = (
  organizationId: string,
  reviewId: string,
  reviewCycleId: string,
  reviewCycleTeamId: string,
) =>
  useQuery({
    queryKey: reviewCycleTeamQueryKeys.reviewCycleTeam(
      organizationId,
      reviewId,
      reviewCycleId,
      reviewCycleTeamId,
    ),
    queryFn: () => fetchReviewCycleTeam(organizationId, reviewId, reviewCycleId, reviewCycleTeamId),
  });

// TODO unused?
export const useDeactivateReviewCycleTeam = (
  organizationId: string,
  reviewId: string,
  reviewCycleId: string,
) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (reviewCycleTeamId: string) =>
      deactivateReviewCycleTeam(organizationId, reviewId, reviewCycleId, reviewCycleTeamId),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: reviewCycleTeamQueryKeys.list(organizationId, reviewId, reviewCycleId),
      });
    },
  });
};

/**
 * Review Assignment
 */

const reviewAssignmentQueryKeys = {
  all: (organizationId: string, reviewId: string, reviewCycleId: string) =>
    [
      'organization',
      organizationId,
      'review',
      reviewId,
      'reviewCycle',
      reviewCycleId,
      'reviewAssignment',
    ] as const,
  list: (
    organizationId: string,
    reviewId: string,
    reviewCycleId: string,
    filters: Partial<FetchReviewAssignmentsFilters> = {},
    pagination: Partial<PaginationParams> = {},
  ) =>
    [
      ...reviewAssignmentQueryKeys.all(organizationId, reviewId, reviewCycleId),
      filters,
      pagination,
    ] as const,
  listSearchTeams: (
    organizationId: string,
    reviewId: string,
    reviewCycleId: string,
    filters: Partial<FetchReviewAssignmentTeamsFilters> = {},
  ) =>
    [
      ...reviewAssignmentQueryKeys.all(organizationId, reviewId, reviewCycleId),
      filters,
      'teams',
    ] as const,
  listSearchRecipients: (
    organizationId: string,
    reviewId: string,
    reviewCycleId: string,
    filters: Partial<FetchReviewAssignmentRecipientsFilters> = {},
  ) =>
    [
      ...reviewAssignmentQueryKeys.all(organizationId, reviewId, reviewCycleId),
      filters,
      'recipients',
    ] as const,
  listSearchAuthors: (
    organizationId: string,
    reviewId: string,
    reviewCycleId: string,
    filters: Partial<FetchReviewAssignmentAuthorsFilters> = {},
  ) =>
    [
      ...reviewAssignmentQueryKeys.all(organizationId, reviewId, reviewCycleId),
      filters,
      'authors',
    ] as const,
  userAssignments: (organizationId: string) =>
    ['organization', organizationId, 'reviewAssignment'] as const,
  listByAuthor: (
    organizationId: string,
    filters: Partial<FetchReviewAssignmentsByAuthorFilters> = {},
    pagination: Partial<PaginationParams> = {},
  ) =>
    [
      ...reviewAssignmentQueryKeys.userAssignments(organizationId),
      'author',
      filters,
      pagination,
    ] as const,
  listByAuthorSearchTeams: (
    organizationId: string,
    filters: Partial<FetchReviewAssignmentByAuthorTeamsFilters> = {},
  ) =>
    [
      ...reviewAssignmentQueryKeys.userAssignments(organizationId),
      'author',
      filters,
      'teams',
    ] as const,
  listByAuthorSearchRecipients: (
    organizationId: string,
    filters: Partial<FetchReviewAssignmentByAuthorRecipientsFilters> = {},
  ) =>
    [
      ...reviewAssignmentQueryKeys.userAssignments(organizationId),
      'author',
      filters,
      'recipients',
    ] as const,
  listByAuthorSearchReviews: (
    organizationId: string,
    filters: Partial<FetchReviewAssignmentByAuthorReviewsFilters> = {},
  ) =>
    [
      ...reviewAssignmentQueryKeys.userAssignments(organizationId),
      'author',
      filters,
      'reviews',
    ] as const,
  reviewAssignmentByAuthor: (organizationId: string, reviewAssignmentId: string) =>
    [
      ...reviewAssignmentQueryKeys.userAssignments(organizationId),
      reviewAssignmentId,
      'open',
    ] as const,
  listByRecipient: (
    organizationId: string,
    filters: Partial<FetchReviewAssignmentsByRecipientFilters> = {},
    pagination: Partial<PaginationParams> = {},
  ) =>
    [
      ...reviewAssignmentQueryKeys.userAssignments(organizationId),
      'recipient',
      filters,
      pagination,
    ] as const,
  listByRecipientSearchTeams: (
    organizationId: string,
    filters: Partial<FetchReviewAssignmentByRecipientTeamsFilters> = {},
  ) =>
    [
      ...reviewAssignmentQueryKeys.userAssignments(organizationId),
      'recipient',
      filters,
      'teams',
    ] as const,
  listByRecipientSearchAuthors: (
    organizationId: string,
    filters: Partial<FetchReviewAssignmentByRecipientAuthorsFilters> = {},
  ) =>
    [
      ...reviewAssignmentQueryKeys.userAssignments(organizationId),
      'recipient',
      filters,
      'authors',
    ] as const,
  listByRecipientSearchReviews: (
    organizationId: string,
    filters: Partial<FetchReviewAssignmentByRecipientReviewsFilters> = {},
  ) =>
    [
      ...reviewAssignmentQueryKeys.userAssignments(organizationId),
      'recipient',
      filters,
      'reviews',
    ] as const,
  reviewAssignmentByRecipient: (organizationId: string, reviewAssignmentId: string) =>
    [
      ...reviewAssignmentQueryKeys.userAssignments(organizationId),
      reviewAssignmentId,
      'collect',
    ] as const,
  listSectionsQuestions: (organizationId: string, reviewAssignmentId: string) =>
    [
      ...reviewAssignmentQueryKeys.userAssignments(organizationId),
      reviewAssignmentId,
      'sectionsQuestions',
    ] as const,
};

export const useFetchReviewAssignments = (
  organizationId: string,
  reviewId: string,
  reviewCycleId: string,
  filters: Partial<FetchReviewAssignmentsFilters>,
  pagination: PaginationParams,
) =>
  useQuery({
    queryKey: reviewAssignmentQueryKeys.list(
      organizationId,
      reviewId,
      reviewCycleId,
      filters,
      pagination,
    ),
    queryFn: () =>
      fetchReviewAssignments(organizationId, reviewId, reviewCycleId, filters, pagination),
    placeholderData: keepPreviousData,
  });

export const useFetchReviewAssignmentTeams = (
  organizationId: string,
  reviewId: string,
  reviewCycleId: string,
  filters: Partial<FetchReviewAssignmentTeamsFilters>,
) =>
  useQuery({
    queryKey: reviewAssignmentQueryKeys.listSearchTeams(
      organizationId,
      reviewId,
      reviewCycleId,
      filters,
    ),
    queryFn: () => fetchReviewAssignmentTeams(organizationId, reviewId, reviewCycleId, filters),
    enabled: !filters.name || filters.name.length <= AVAILABILITY_MAX_LENGTH,
    initialData: {
      data: [],
      totalItems: 0,
    },
  });

export const useFetchReviewAssignmentRecipients = (
  organizationId: string,
  reviewId: string,
  reviewCycleId: string,
  filters: Partial<FetchReviewAssignmentRecipientsFilters>,
) =>
  useQuery({
    queryKey: reviewAssignmentQueryKeys.listSearchRecipients(
      organizationId,
      reviewId,
      reviewCycleId,
      filters,
    ),
    queryFn: () =>
      fetchReviewAssignmentRecipients(organizationId, reviewId, reviewCycleId, filters),
    enabled: !filters.name || filters.name.length <= AVAILABILITY_MAX_LENGTH,
    initialData: {
      data: [],
      totalItems: 0,
    },
  });

export const useFetchReviewAssignmentAuthors = (
  organizationId: string,
  reviewId: string,
  reviewCycleId: string,
  filters: Partial<FetchReviewAssignmentAuthorsFilters>,
) =>
  useQuery({
    queryKey: reviewAssignmentQueryKeys.listSearchAuthors(
      organizationId,
      reviewId,
      reviewCycleId,
      filters,
    ),
    queryFn: () => fetchReviewAssignmentAuthors(organizationId, reviewId, reviewCycleId, filters),
    enabled: !filters.name || filters.name.length <= AVAILABILITY_MAX_LENGTH,
    initialData: {
      data: [],
      totalItems: 0,
    },
  });

export const useDeactivateReviewAssignment = (
  organizationId: string,
  reviewId: string,
  reviewCycleId: string,
) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (reviewAssignmentId: string) =>
      deactivateReviewAssignment(organizationId, reviewId, reviewCycleId, reviewAssignmentId),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: reviewAssignmentQueryKeys.list(organizationId, reviewId, reviewCycleId),
      });
    },
  });
};

export const useFetchReviewAssignmentsByAuthor = (
  organizationId: string,
  filters: Partial<FetchReviewAssignmentsByAuthorFilters>,
  pagination: PaginationParams,
) =>
  useQuery({
    queryKey: reviewAssignmentQueryKeys.listByAuthor(organizationId, filters, pagination),
    queryFn: () => fetchReviewAssignmentsByAuthor(organizationId, filters, pagination),
    placeholderData: keepPreviousData,
  });

export const useFetchReviewAssignmentByAuthor = (
  organizationId: string,
  reviewAssignmentId: string,
) =>
  useQuery({
    queryKey: reviewAssignmentQueryKeys.reviewAssignmentByAuthor(
      organizationId,
      reviewAssignmentId,
    ),
    queryFn: () => openReviewAssignment(organizationId, reviewAssignmentId),
    placeholderData: keepPreviousData,
  });

export const useFetchReviewAssignmentByAuthorTeams = (
  organizationId: string,
  filters: Partial<FetchReviewAssignmentByAuthorTeamsFilters>,
) =>
  useQuery({
    queryKey: reviewAssignmentQueryKeys.listByAuthorSearchTeams(organizationId, filters),
    queryFn: () => fetchReviewAssignmentByAuthorTeams(organizationId, filters),
    enabled: !filters.name || filters.name.length <= AVAILABILITY_MAX_LENGTH,
    initialData: {
      data: [],
      totalItems: 0,
    },
  });

export const useFetchReviewAssignmentByAuthorRecipients = (
  organizationId: string,
  filters: Partial<FetchReviewAssignmentByAuthorRecipientsFilters>,
) =>
  useQuery({
    queryKey: reviewAssignmentQueryKeys.listByAuthorSearchRecipients(organizationId, filters),
    queryFn: () => fetchReviewAssignmentByAuthorRecipients(organizationId, filters),
    enabled: !filters.name || filters.name.length <= AVAILABILITY_MAX_LENGTH,
    initialData: {
      data: [],
      totalItems: 0,
    },
  });

export const useFetchReviewAssignmentByAuthorReviews = (
  organizationId: string,
  filters: Partial<FetchReviewAssignmentByAuthorReviewsFilters>,
) =>
  useQuery({
    queryKey: reviewAssignmentQueryKeys.listByAuthorSearchReviews(organizationId, filters),
    queryFn: () => fetchReviewAssignmentByAuthorReviews(organizationId, filters),
    enabled: !filters.name || filters.name.length <= AVAILABILITY_MAX_LENGTH,
    initialData: {
      data: [],
      totalItems: 0,
    },
  });

export const useFetchReviewAssignmentsByRecipient = (
  organizationId: string,
  filters: Partial<FetchReviewAssignmentsByRecipientFilters>,
  pagination: PaginationParams,
) =>
  useQuery({
    queryKey: reviewAssignmentQueryKeys.listByRecipient(organizationId, filters, pagination),
    queryFn: () => fetchReviewAssignmentsByRecipient(organizationId, filters, pagination),
    placeholderData: keepPreviousData,
  });

export const useFetchReviewAssignmentByRecipientTeams = (
  organizationId: string,
  filters: Partial<FetchReviewAssignmentByRecipientTeamsFilters>,
) =>
  useQuery({
    queryKey: reviewAssignmentQueryKeys.listByRecipientSearchTeams(organizationId, filters),
    queryFn: () => fetchReviewAssignmentByRecipientTeams(organizationId, filters),
    enabled: !filters.name || filters.name.length <= AVAILABILITY_MAX_LENGTH,
    initialData: {
      data: [],
      totalItems: 0,
    },
  });

export const useFetchReviewAssignmentByRecipientAuthors = (
  organizationId: string,
  filters: Partial<FetchReviewAssignmentByRecipientAuthorsFilters>,
) =>
  useQuery({
    queryKey: reviewAssignmentQueryKeys.listByRecipientSearchAuthors(organizationId, filters),
    queryFn: () => fetchReviewAssignmentByRecipientAuthors(organizationId, filters),
    enabled: !filters.name || filters.name.length <= AVAILABILITY_MAX_LENGTH,
    initialData: {
      data: [],
      totalItems: 0,
    },
  });

export const useFetchReviewAssignmentByRecipientReviews = (
  organizationId: string,
  filters: Partial<FetchReviewAssignmentByRecipientReviewsFilters>,
) =>
  useQuery({
    queryKey: reviewAssignmentQueryKeys.listByRecipientSearchReviews(organizationId, filters),
    queryFn: () => fetchReviewAssignmentByRecipientReviews(organizationId, filters),
    enabled: !filters.name || filters.name.length <= AVAILABILITY_MAX_LENGTH,
    initialData: {
      data: [],
      totalItems: 0,
    },
  });

export const useFetchReviewAssignmentByRecipient = (
  organizationId: string,
  reviewAssignmentId: string,
) =>
  useQuery({
    queryKey: reviewAssignmentQueryKeys.reviewAssignmentByRecipient(
      organizationId,
      reviewAssignmentId,
    ),
    queryFn: () => collectReviewAssignment(organizationId, reviewAssignmentId),
    placeholderData: keepPreviousData,
  });

export const useFetchReviewAssignmentSectionsQuestions = (
  organizationId: string,
  reviewAssignmentId: string,
) =>
  useQuery({
    queryKey: reviewAssignmentQueryKeys.listSectionsQuestions(organizationId, reviewAssignmentId),
    queryFn: () => fetchReviewAssignmentSectionsQuestions(organizationId, reviewAssignmentId),
    placeholderData: keepPreviousData,
  });

export const useOpenReviewAssignment = (organizationId: string) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (reviewAssignmentId: string) =>
      openReviewAssignment(organizationId, reviewAssignmentId),
    onSuccess: (data) => {
      queryClient.invalidateQueries({
        queryKey: reviewAssignmentQueryKeys.listByAuthor(organizationId),
      });
      queryClient.setQueryData(
        reviewAssignmentQueryKeys.reviewAssignmentByAuthor(
          organizationId,
          data.reviewAssignment.id,
        ),
        data,
      );
    },
  });
};

// TODO unused?
export const useSaveReviewAssignment = (organizationId: string) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({
      reviewAssignmentId,
      payload,
    }: {
      reviewAssignmentId: string;
      payload: SaveAnswersRequest;
    }) => saveReviewAssignment(organizationId, reviewAssignmentId, payload),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: reviewAssignmentQueryKeys.listByAuthor(organizationId),
      });
    },
  });
};

export const useCompleteReviewAssignment = (organizationId: string) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({
      reviewAssignmentId,
      payload,
    }: {
      reviewAssignmentId: string;
      payload: SaveAnswersRequest;
    }) => completeReviewAssignment(organizationId, reviewAssignmentId, payload),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: reviewAssignmentQueryKeys.listByAuthor(organizationId),
      });
    },
  });
};

export const useCollectReviewAssignment = (organizationId: string) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (reviewAssignmentId: string) =>
      collectReviewAssignment(organizationId, reviewAssignmentId),
    onSuccess: (data) => {
      queryClient.invalidateQueries({
        queryKey: reviewAssignmentQueryKeys.listByRecipient(organizationId),
      });
      queryClient.setQueryData(
        reviewAssignmentQueryKeys.reviewAssignmentByRecipient(
          organizationId,
          data.reviewAssignment.id,
        ),
        data,
      );
    },
  });
};

/**
 * Observer
 */

const observerQueryKeys = {
  all: (organizationId: string) => ['organization', organizationId, 'observer'] as const,
  list: (
    organizationId: string,
    filters: Partial<FetchObserversFilters> = {},
    pagination: Partial<PaginationParams> = {},
  ) => [...observerQueryKeys.all(organizationId), filters, pagination] as const,
  availableReviewsForObserver: (organizationId: string, userId: string, reviewName: string) =>
    [
      ...observerQueryKeys.all(organizationId),
      'user',
      userId,
      'reviews-availability',
      reviewName,
    ] as const,
  availableUsersForObserver: (organizationId: string, reviewId: string, userName: string) =>
    [
      ...observerQueryKeys.all(organizationId),
      'review',
      reviewId,
      'users-availability',
      userName,
    ] as const,
};

export const useFetchObservers = (
  organizationId: string,
  filters: Partial<FetchObserversFilters>,
  pagination: PaginationParams,
) =>
  useQuery({
    queryKey: observerQueryKeys.list(organizationId, filters, pagination),
    queryFn: () => fetchObservers(organizationId, filters, pagination),
    placeholderData: keepPreviousData,
  });

export const useCreateObserver = (organizationId: string) => {
  const queryClient = useQueryClient();
  const invalidateReview = useInvalidateReview(organizationId);

  return useMutation({
    mutationFn: (payload: CreateObserverPayload) => createObserver(organizationId, payload),
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: observerQueryKeys.list(organizationId) });

      invalidateReview({ id: data.observer.review.id });
    },
  });
};

export const useDeactivateObserver = (organizationId: string) => {
  const queryClient = useQueryClient();
  const invalidateReview = useInvalidateReview(organizationId);

  return useMutation({
    mutationFn: (observerId: string) => deactivateObserver(organizationId, observerId),
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: observerQueryKeys.list(organizationId) });

      invalidateReview({ id: data.observer.review.id });
    },
  });
};

export const useFetchAvailableReviewsForObserver = (
  organizationId: string,
  userId: string,
  reviewName: string,
) =>
  useQuery({
    queryKey: observerQueryKeys.availableReviewsForObserver(organizationId, userId, reviewName),
    queryFn: () =>
      fetchAvailableReviewsForObserver(organizationId, userId, reviewName, reviewName.length === 0),
    enabled: reviewName.length <= AVAILABILITY_MAX_LENGTH,
    initialData: {
      data: [],
      totalItems: 0,
    },
  });

export const useFetchAvailableUsersForObserver = (
  organizationId: string,
  reviewId: string,
  userName: string,
) =>
  useQuery({
    queryKey: observerQueryKeys.availableUsersForObserver(organizationId, reviewId, userName),
    queryFn: () =>
      fetchAvailableUsersForObserver(organizationId, reviewId, userName, userName.length === 0),
    enabled: userName.length <= AVAILABILITY_MAX_LENGTH,
    initialData: {
      data: [],
      totalItems: 0,
    },
  });

/**
 * Observatory
 */

const observatoryQueryKeys = {
  all: (organizationId: string) => ['organization', organizationId, 'observatory'] as const,
  observers: (
    organizationId: string,
    filters: Partial<FetchObservatoryObserversFilters> = {},
    pagination: Partial<PaginationParams> = {},
  ) => [...observatoryQueryKeys.all(organizationId), 'observers', filters, pagination] as const,
  reviewAssignments: (
    organizationId: string,
    reviewId: string,
    filters: Partial<FetchObservatoryReviewAssignmentsFilters> = {},
    pagination: Partial<PaginationParams> = {},
  ) =>
    [
      ...observatoryQueryKeys.all(organizationId),
      reviewId,
      'reviewAssignments',
      filters,
      pagination,
    ] as const,
  reviewCycles: (
    organizationId: string,
    reviewId: string,
    filters: Partial<FetchObservatoryReviewCyclesFilters> = {},
    pagination: Partial<PaginationParams> = {},
  ) =>
    [
      ...observatoryQueryKeys.all(organizationId),
      reviewId,
      'reviewCycles',
      filters,
      pagination,
    ] as const,
  reviewTeams: (
    organizationId: string,
    reviewId: string,
    filters: Partial<FetchObservatoryReviewTeamsFilters> = {},
    pagination: Partial<PaginationParams> = {},
  ) =>
    [
      ...observatoryQueryKeys.all(organizationId),
      reviewId,
      'reviewTeams',
      filters,
      pagination,
    ] as const,
  reviewSections: (organizationId: string, reviewId: string) =>
    [...observatoryQueryKeys.all(organizationId), reviewId, 'sections'] as const,
};

export const useFetchObservatoryObservers = (
  organizationId: string,
  filters: Partial<FetchObservatoryObserversFilters>,
  pagination: PaginationParams,
) =>
  useQuery({
    queryKey: observatoryQueryKeys.observers(organizationId, filters, pagination),
    queryFn: () => fetchObservatoryObservers(organizationId, filters, pagination),
    placeholderData: keepPreviousData,
  });

export const useFetchObservatoryReviewAssignments = (
  organizationId: string,
  reviewId: string,
  filters: Partial<FetchObservatoryReviewAssignmentsFilters>,
  pagination: PaginationParams,
) =>
  useQuery({
    queryKey: observatoryQueryKeys.reviewAssignments(organizationId, reviewId, filters, pagination),
    queryFn: () => fetchObservatoryReviewAssignments(organizationId, reviewId, filters, pagination),
    placeholderData: keepPreviousData,
  });

export const useFetchObservatoryReviewCycles = (
  organizationId: string,
  reviewId: string,
  filters: Partial<FetchObservatoryReviewCyclesFilters>,
  pagination: PaginationParams,
) =>
  useQuery({
    queryKey: observatoryQueryKeys.reviewCycles(organizationId, reviewId, filters, pagination),
    queryFn: () => fetchObservatoryReviewCycles(organizationId, reviewId, filters, pagination),
    placeholderData: keepPreviousData,
  });

export const useFetchObservatoryReviewTeams = (
  organizationId: string,
  reviewId: string,
  filters: Partial<FetchObservatoryReviewTeamsFilters>,
  pagination: PaginationParams,
) =>
  useQuery({
    queryKey: observatoryQueryKeys.reviewTeams(organizationId, reviewId, filters, pagination),
    queryFn: () => fetchObservatoryReviewTeams(organizationId, reviewId, filters, pagination),
    placeholderData: keepPreviousData,
  });

export const useFetchObservatoryReviewSections = (organizationId: string, reviewId: string) =>
  useQuery({
    queryKey: observatoryQueryKeys.reviewSections(organizationId, reviewId),
    queryFn: () => fetchObservatoryReviewSections(organizationId, reviewId),
    placeholderData: keepPreviousData,
  });
