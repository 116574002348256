import { useCallback, useMemo } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import {
  useQueryParams,
  withDefault,
  NumberParam,
  ArrayParam,
  JsonParam,
  type QueryParamConfig,
} from 'use-query-params';

import {
  SortOrder,
  PAGINATION_DEFAULT_PAGE,
  PAGINATION_DEFAULT_SIZE,
  fromApiPage,
  toApiPage,
  type PaginationParams,
} from '@/api';
import { type TableSortOrder } from '@/common/components';

const DEBOUNCE_FILTERS_DELAY_MS = 500;

const DEFAULT_PAGE = fromApiPage(PAGINATION_DEFAULT_PAGE);

export type Filters = Record<string, unknown>;

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
const PageParam = withDefault(NumberParam, DEFAULT_PAGE) as QueryParamConfig<number>;

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
const SizeParam = withDefault(NumberParam, PAGINATION_DEFAULT_SIZE) as QueryParamConfig<number>;

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
const SortParam = withDefault(ArrayParam, undefined) as QueryParamConfig<
  [string, SortOrder] | undefined
>;

const FiltersParam = withDefault(JsonParam, undefined);

const usePaginationFilters = <F extends Filters>(defaultSort?: [string, SortOrder]) => {
  const [{ page, size, sort = defaultSort, filters }, setQuery] = useQueryParams(
    {
      page: PageParam,
      size: SizeParam,
      sort: SortParam,
      filters: FiltersParam as QueryParamConfig<F | undefined>, // eslint-disable-line @typescript-eslint/consistent-type-assertions
    },
    { removeDefaultsFromUrl: true },
  );

  const onPaginationChange = useCallback(
    (page: number, size?: number) =>
      setQuery({
        page,
        ...(size === undefined ? {} : { size }),
      }),
    [setQuery],
  );

  const onSortChange = useCallback(
    (field?: string, order?: TableSortOrder) =>
      setQuery({
        page: DEFAULT_PAGE,
        sort:
          field && field !== '' && order
            ? [field, order === 'ascend' ? SortOrder.Asc : SortOrder.Desc]
            : undefined,
      }),
    [setQuery],
  );

  const onFiltersChange = useDebouncedCallback((changedFilters: F) => {
    const newFilters = deleteUndefinedProperties({
      ...filters,
      ...replaceEmptyStringWithUndefined(changedFilters),
    });

    setQuery({
      page: DEFAULT_PAGE,
      filters: Object.keys(newFilters).length > 0 ? newFilters : undefined,
    });
  }, DEBOUNCE_FILTERS_DELAY_MS);

  const onFiltersClear = useCallback(() => {
    onFiltersChange.cancel();

    setQuery({
      page: DEFAULT_PAGE,
      filters: undefined,
    });
  }, [onFiltersChange, setQuery]);

  return useMemo(
    () => ({
      pagination: {
        page: toApiPage(page),
        size,
        sort: sort ? [`${sort[0]},${sort[1]}`] : undefined,
      } satisfies PaginationParams,
      filters: (filters ?? {}) as F, // eslint-disable-line @typescript-eslint/consistent-type-assertions
      onPaginationChange,
      onSortChange,
      onFiltersChange,
      onFiltersClear,
    }),
    [page, size, sort, filters, onFiltersChange, onFiltersClear, onPaginationChange, onSortChange],
  );
};

export default usePaginationFilters;

const replaceEmptyStringWithUndefined = <T extends Filters>(obj: T) =>
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  Object.fromEntries(
    Object.entries(obj).map(([key, val]) => [key, val === '' ? undefined : val]),
  ) as T;

const deleteUndefinedProperties = <T extends Filters>(obj: T) =>
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  Object.fromEntries(Object.entries(obj).filter(([, val]) => val !== undefined)) as T;
