import { generatePath, useParams } from 'react-router-dom';
import { HomeOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { type BreadcrumbsRoute } from 'use-react-router-breadcrumbs';

import { useFetchReview, useFetchTeam, useFetchUser } from '@/api';
import { useAuth, UserPermission } from '@/common/auth';

/**
 * Routes
 */
export const DASHBOARD_ROUTES = {
  index: {
    path: 'app/*',
    to: '/app',
    absPath: '/app',
    Breadcrumb: () => <HomeOutlined />,
  },
  home: {
    path: 'home',
    to: '/app/home',
    absPath: '/app/home',
    Breadcrumb: undefined,
  },
  organization: {
    path: 'organization/*',
    to: '/app/organization',
    absPath: '/app/organization',
    Breadcrumb() {
      const { t } = useTranslation();
      return <>{t('organization:breadcrumbs.index')}</>;
    },
    permissions: [UserPermission.ManageOrganization],
  },
  organizationUpdate: {
    path: 'update',
    to: '/app/organization/update',
    absPath: '/app/organization/update',
    Breadcrumb: undefined,
  },
  organizationRemoval: {
    path: 'removal',
    to: '/app/organization/removal',
    absPath: '/app/organization/removal',
    Breadcrumb: undefined,
  },
  profile: {
    path: 'profile/*',
    to: '/app/profile',
    absPath: '/app/profile',
    Breadcrumb() {
      const { t } = useTranslation();
      return <>{t('profile:breadcrumbs.index')}</>;
    },
  },
  profileUpdate: {
    path: 'update',
    to: '/app/profile/update',
    absPath: '/app/profile/update',
    Breadcrumb: undefined,
  },
  profileChangePassword: {
    path: 'change-password',
    to: '/app/profile/change-password',
    absPath: '/app/profile/change-password',
    Breadcrumb: undefined,
  },
  users: {
    path: 'users/*',
    to: '/app/users',
    absPath: '/app/users',
    Breadcrumb() {
      const { t } = useTranslation();
      return <>{t('users:breadcrumbs.list')}</>;
    },
    permissions: [
      UserPermission.ManageUsers,
      UserPermission.ManageTeams,
      UserPermission.ManageReviews,
    ],
  },
  usersList: {
    path: 'list',
    to: '/app/users/list',
    absPath: '/app/users/list',
    Breadcrumb: undefined,
  },
  userDetail: {
    path: ':userId/*',
    to: (userId: string) => generatePath('/app/users/:userId', { userId }),
    absPath: '/app/users/:userId',
    Breadcrumb() {
      const { organizationId } = useAuth();
      const { userId } = useUserParams();
      const { data } = useFetchUser(organizationId, userId);
      return <>{data?.user ? data.user.name : ''}</>;
    },
  },
  userDetailSettings: {
    path: 'settings/*',
    to: (userId: string) => generatePath('/app/users/:userId/settings', { userId }),
    absPath: '/app/users/:userId/settings',
    Breadcrumb() {
      const { t } = useTranslation();
      return <>{t('users:breadcrumbs.settings')}</>;
    },
    permissions: [UserPermission.ManageUsers],
  },
  userDetailSettingsGeneral: {
    path: 'general',
    to: (userId: string) => generatePath('/app/users/:userId/settings/general', { userId }),
    absPath: '/app/users/:userId/settings/general',
    Breadcrumb: undefined,
  },
  userDetailSettingsPermissions: {
    path: 'permissions',
    to: (userId: string) => generatePath('/app/users/:userId/settings/permissions', { userId }),
    absPath: '/app/users/:userId/settings/permissions',
    Breadcrumb: undefined,
  },
  userDetailTeams: {
    path: 'teams',
    to: (userId: string) => generatePath('/app/users/:userId/teams', { userId }),
    absPath: '/app/users/:userId/teams',
    Breadcrumb() {
      const { t } = useTranslation();
      return <>{t('users:breadcrumbs.teams')}</>;
    },
    permissions: [UserPermission.ManageTeams, UserPermission.ManageReviews],
  },
  userDetailObservers: {
    path: 'observers',
    to: (userId: string) => generatePath('/app/users/:userId/observers', { userId }),
    absPath: '/app/users/:userId/observers',
    Breadcrumb() {
      const { t } = useTranslation();
      return <>{t('users:breadcrumbs.observers')}</>;
    },
    permissions: [UserPermission.ManageReviews],
  },
  teams: {
    path: 'teams/*',
    to: '/app/teams',
    absPath: '/app/teams',
    Breadcrumb() {
      const { t } = useTranslation();
      return <>{t('teams:breadcrumbs.list')}</>;
    },
    permissions: [UserPermission.ManageTeams, UserPermission.ManageReviews],
  },
  teamsList: {
    path: 'list',
    to: '/app/teams/list',
    absPath: '/app/teams/list',
    Breadcrumb: undefined,
  },
  teamDetail: {
    path: ':teamId/*',
    to: (teamId: string) => generatePath('/app/teams/:teamId', { teamId }),
    absPath: '/app/teams/:teamId',
    Breadcrumb() {
      const { organizationId } = useAuth();
      const { teamId } = useTeamParams();
      const { data } = useFetchTeam(organizationId, teamId);
      return <>{data?.team ? data.team.name : ''}</>;
    },
  },
  teamDetailSettings: {
    path: 'settings/*',
    to: (teamId: string) => generatePath('/app/teams/:teamId/settings', { teamId }),
    absPath: '/app/teams/:teamId/settings',
    Breadcrumb() {
      const { t } = useTranslation();
      return <>{t('teams:breadcrumbs.settings')}</>;
    },
    permissions: [UserPermission.ManageTeams],
  },
  teamDetailSettingsGeneral: {
    path: 'general',
    to: (teamId: string) => generatePath('/app/teams/:teamId/settings/general', { teamId }),
    absPath: '/app/teams/:teamId/settings/general',
    Breadcrumb: undefined,
  },
  teamDetailSettingsManagers: {
    path: 'managers',
    to: (teamId: string) => generatePath('/app/teams/:teamId/settings/managers', { teamId }),
    absPath: '/app/teams/:teamId/settings/managers',
    Breadcrumb: undefined,
  },
  teamDetailSettingsMembers: {
    path: 'members',
    to: (teamId: string) => generatePath('/app/teams/:teamId/settings/members', { teamId }),
    absPath: '/app/teams/:teamId/settings/members',
    Breadcrumb: undefined,
  },
  teamDetailUsers: {
    path: 'users',
    to: (teamId: string) => generatePath('/app/teams/:teamId/users', { teamId }),
    absPath: '/app/teams/:teamId/users',
    Breadcrumb() {
      const { t } = useTranslation();
      return <>{t('teams:breadcrumbs.users')}</>;
    },
    forbidPermissions: [UserPermission.ManageTeams],
  },
  teamDetailReviews: {
    path: 'reviewTeams',
    to: (teamId: string) => generatePath('/app/teams/:teamId/reviewTeams', { teamId }),
    absPath: '/app/teams/:teamId/reviewTeams',
    Breadcrumb() {
      const { t } = useTranslation();
      return <>{t('teams:breadcrumbs.reviewTeams')}</>;
    },
    permissions: [UserPermission.ManageReviews],
  },
  reviews: {
    path: 'reviews/*',
    to: '/app/reviews',
    absPath: '/app/reviews',
    Breadcrumb() {
      const { t } = useTranslation();
      return <>{t('reviews:breadcrumbs.list')}</>;
    },
    permissions: [UserPermission.ManageReviews],
  },
  reviewsList: {
    path: 'list',
    to: '/app/reviews/list',
    absPath: '/app/reviews/list',
    Breadcrumb: undefined,
  },
  reviewsCreate: {
    path: 'create',
    to: '/app/reviews/create',
    absPath: '/app/reviews/create',
    Breadcrumb() {
      const { t } = useTranslation();
      return <>{t('reviews:breadcrumbs.reviewsCreate')}</>;
    },
  },
  reviewDetail: {
    path: ':reviewId/*',
    to: (reviewId: string) => generatePath('/app/reviews/:reviewId', { reviewId }),
    absPath: '/app/reviews/:reviewId',
    Breadcrumb() {
      const { organizationId } = useAuth();
      const { reviewId } = useReviewParams();
      const { data } = useFetchReview(organizationId, reviewId);
      return <>{data?.review ? data.review.name : ''}</>;
    },
  },
  reviewDetailSettings: {
    path: 'settings/*',
    to: (reviewId: string) => generatePath('/app/reviews/:reviewId/settings', { reviewId }),
    absPath: '/app/reviews/:reviewId/settings',
    Breadcrumb() {
      const { t } = useTranslation();
      return <>{t('reviews:breadcrumbs.settings')}</>;
    },
  },
  reviewDetailSettingsGeneral: {
    path: 'general',
    to: (reviewId: string) => generatePath('/app/reviews/:reviewId/settings/general', { reviewId }),
    absPath: '/app/reviews/:reviewId/settings/general',
    Breadcrumb: undefined,
  },
  reviewDetailSettingsCycle: {
    path: 'cycle',
    to: (reviewId: string) => generatePath('/app/reviews/:reviewId/settings/cycle', { reviewId }),
    absPath: '/app/reviews/:reviewId/settings/cycle',
    Breadcrumb: undefined,
  },
  reviewDetailSettingsQuestions: {
    path: 'questions',
    to: (reviewId: string) =>
      generatePath('/app/reviews/:reviewId/settings/questions', { reviewId }),
    absPath: '/app/reviews/:reviewId/settings/questions',
    Breadcrumb: undefined,
  },
  reviewDetailSettingsTeams: {
    path: 'teams',
    to: (reviewId: string) => generatePath('/app/reviews/:reviewId/settings/teams', { reviewId }),
    absPath: '/app/reviews/:reviewId/settings/teams',
    Breadcrumb: undefined,
  },
  reviewDetailSettingsObservers: {
    path: 'observers',
    to: (reviewId: string) =>
      generatePath('/app/reviews/:reviewId/settings/observers', { reviewId }),
    absPath: '/app/reviews/:reviewId/settings/observers',
    Breadcrumb: undefined,
  },
  reviewDetailReviewCycles: {
    path: 'review-cycles',
    to: (reviewId: string) => generatePath('/app/reviews/:reviewId/review-cycles', { reviewId }),
    absPath: '/app/reviews/:reviewId/review-cycles',
    Breadcrumb() {
      const { t } = useTranslation();
      return <>{t('reviews:breadcrumbs.reviewCycles')}</>;
    },
  },
  reviewDetailReviewAssignments: {
    path: ':reviewId/review-cycles/:reviewCycleId/review-assignments',
    to: (reviewId: string, reviewCycleId: string) =>
      generatePath('/app/reviews/:reviewId/review-cycles/:reviewCycleId/review-assignments', {
        reviewId,
        reviewCycleId,
      }),
    absPath: '/app/reviews/:reviewId/review-cycles/:reviewCycleId/review-assignments',
    Breadcrumb() {
      const { t } = useTranslation();
      return <>{t('reviews:breadcrumbs.cycleDetail')}</>;
    },
  },
  completeReviewAssignments: {
    path: 'complete-review-assignments/*',
    to: '/app/complete-review-assignments',
    absPath: '/app/complete-review-assignments',
    Breadcrumb() {
      const { t } = useTranslation();
      return <>{t('assignments:breadcrumbs.completeReviewAssignments')}</>;
    },
  },
  completeReviewAssignmentsList: {
    path: 'list',
    to: '/app/complete-review-assignments/list',
    absPath: '/app/complete-review-assignments/list',
    Breadcrumb: undefined,
  },
  completeReviewAssignmentsDetail: {
    path: 'detail/:recipientUserId/:reviewAssignmentId/*',
    to: (recipientUserId: string, reviewAssignmentId: string) =>
      generatePath('/app/complete-review-assignments/detail/:recipientUserId/:reviewAssignmentId', {
        recipientUserId,
        reviewAssignmentId,
      }),
    absPath: '/app/complete-review-assignments/detail/:recipientUserId/:reviewAssignmentId',
    Breadcrumb() {
      const { organizationId } = useAuth();
      const { recipientUserId } = useCompleteReviewAssignmentDetailParams();
      const { data } = useFetchUser(organizationId, recipientUserId);
      return <>{data?.user ? data.user.name : ''}</>;
    },
  },
  collectReviewAssignments: {
    path: 'collect-review-assignments/*',
    to: '/app/collect-review-assignments',
    absPath: '/app/collect-review-assignments',
    Breadcrumb() {
      const { t } = useTranslation();
      return <>{t('assignments:breadcrumbs.collectReviewAssignments')}</>;
    },
  },
  collectResultsList: {
    path: 'list',
    to: '/app/collect-review-assignments/list',
    absPath: '/app/collect-review-assignments/list',
    Breadcrumb: undefined,
  },
  collectResultsDetail: {
    path: 'detail/:authorUserId/:reviewAssignmentId/*',
    to: (authorUserId: string, reviewAssignmentId: string) =>
      generatePath('/app/collect-review-assignments/detail/:authorUserId/:reviewAssignmentId', {
        authorUserId,
        reviewAssignmentId,
      }),
    absPath: '/app/collect-review-assignments/detail/:authorUserId/:reviewAssignmentId',
    Breadcrumb() {
      const { organizationId } = useAuth();
      const { authorUserId } = useCollectReviewAssignmentsDetailParams();
      const { data } = useFetchUser(organizationId, authorUserId);
      return <>{data?.user ? data.user.name : ''}</>;
    },
  },
  observatory: {
    path: 'observatory/*',
    to: '/app/observatory',
    absPath: '/app/observatory',
    Breadcrumb() {
      const { t } = useTranslation();
      return <>{t('observatory:breadcrumbs.index')}</>;
    },
  },
  observatoryObserversList: {
    path: 'observers',
    to: '/app/observatory/observers',
    absPath: '/app/observatory/observers',
    Breadcrumb: undefined,
  },
  observatoryReviewAssignments: {
    path: 'review-assignments/:reviewId/*',
    to: (reviewId: string) =>
      generatePath('/app/observatory/review-assignments/:reviewId', { reviewId }),
    absPath: '/app/observatory/review-assignments/:reviewId',
    Breadcrumb() {
      const { organizationId } = useAuth();
      const { reviewId } = useObservatoryReviewAssignmentsParams();
      const { data } = useFetchReview(organizationId, reviewId);
      return <>{data?.review ? data.review.name : ''}</>;
    },
  },
  observatoryReviewAssignmentsSummary: {
    path: 'summary',
    to: (reviewId: string) =>
      generatePath('/app/observatory/review-assignments/:reviewId/summary', { reviewId }),
    absPath: '/app/observatory/review-assignments/:reviewId/summary',
    Breadcrumb() {
      const { t } = useTranslation();
      return <>{t('observatory:breadcrumbs.reviewAssignmentsSummary')}</>;
    },
  },
  observatoryReviewAssignmentsQuestions: {
    path: 'questions',
    to: (reviewId: string) =>
      generatePath('/app/observatory/review-assignments/:reviewId/questions', { reviewId }),
    absPath: '/app/observatory/review-assignments/:reviewId/questions',
    Breadcrumb() {
      const { t } = useTranslation();
      return <>{t('observatory:breadcrumbs.reviewAssignmentsQuestions')}</>;
    },
  },
  observatoryReviewAssignmentsList: {
    path: 'list',
    to: (reviewId: string) =>
      generatePath('/app/observatory/review-assignments/:reviewId/list', { reviewId }),
    absPath: '/app/observatory/review-assignments/:reviewId/list',
    Breadcrumb() {
      const { t } = useTranslation();
      return <>{t('observatory:breadcrumbs.reviewAssignmentsList')}</>;
    },
  },
  observatoryReviewAssignmentsRaw: {
    path: 'raw',
    to: (reviewId: string) =>
      generatePath('/app/observatory/review-assignments/:reviewId/raw', { reviewId }),
    absPath: '/app/observatory/review-assignments/:reviewId/raw',
    Breadcrumb() {
      const { t } = useTranslation();
      return <>{t('observatory:breadcrumbs.reviewAssignmentsRaw')}</>;
    },
  },
} as const satisfies Record<
  string,
  {
    path: string;
    to: string | ((...args: string[]) => string);
    absPath: string;
    Breadcrumb: BreadcrumbsRoute['breadcrumb'];
    permissions?: readonly UserPermission[];
    forbidPermissions?: readonly UserPermission[];
  }
>;

/**
 * Get params
 */
export const useUserParams = () => {
  const { userId } = useParams<'userId'>();

  if (!userId) {
    throw new Error('Missing userId parameter');
  }

  return { userId };
};

export const useTeamParams = () => {
  const { teamId } = useParams<'teamId'>();

  if (!teamId) {
    throw new Error('Missing teamId parameter');
  }

  return { teamId };
};

export const useReviewParams = () => {
  const { reviewId } = useParams<'reviewId'>();

  if (!reviewId) {
    throw new Error('Missing reviewId parameter');
  }

  return { reviewId };
};

export const useReviewCycleParams = () => {
  const { reviewId, reviewCycleId } = useParams<'reviewId' | 'reviewCycleId'>();

  if (!reviewId) {
    throw new Error('Missing reviewId parameter');
  }

  if (!reviewCycleId) {
    throw new Error('Missing reviewCycleId parameter');
  }

  return { reviewId, reviewCycleId };
};

export const useCompleteReviewAssignmentDetailParams = () => {
  const { recipientUserId, reviewAssignmentId } = useParams<
    'recipientUserId' | 'reviewAssignmentId'
  >();

  if (!recipientUserId) {
    throw new Error('Missing recipientUserId parameter');
  }

  if (!reviewAssignmentId) {
    throw new Error('Missing reviewAssignmentId parameter');
  }

  return { reviewAssignmentId, recipientUserId };
};

export const useCollectReviewAssignmentsDetailParams = () => {
  const { authorUserId, reviewAssignmentId } = useParams<'authorUserId' | 'reviewAssignmentId'>();

  if (!authorUserId) {
    throw new Error('Missing authorUserId parameter');
  }

  if (!reviewAssignmentId) {
    throw new Error('Missing reviewAssignmentId parameter');
  }

  return { reviewAssignmentId, authorUserId };
};

export const useObservatoryReviewAssignmentsParams = () => {
  const { reviewId } = useParams<'reviewId'>();

  if (!reviewId) {
    throw new Error('Missing reviewId parameter');
  }

  return { reviewId };
};
