import { useCallback } from 'react';
import { App, type ModalFuncProps } from 'antd';
import { useTranslation } from 'react-i18next';

const useConfirmation = (props: Omit<ModalFuncProps, 'onOk'>, onConfirm: VoidFunction) => {
  const { t } = useTranslation();
  const { modal } = App.useApp();

  return useCallback(() => {
    modal.confirm({
      title: t('common:useConfirmation.title'),
      centered: true,
      maskClosable: true,
      ...props,
      onOk: (close: VoidFunction) => {
        onConfirm();
        close();
      },
    });
  }, [modal, props, onConfirm, t]);
};

export default useConfirmation;
