import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';

import '@/i18n';
import Root from '@/app/Root';

dayjs.extend(duration);

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
createRoot(document.querySelector('#root')!).render(
  <StrictMode>
    <Root />
  </StrictMode>,
);
