import { useCallback } from 'react';
import { atom, useSetAtom } from 'jotai';
import { atomWithStorage } from 'jotai/utils';

import { type UserPermission } from '.';

type AuthState = {
  organizationId: string;
  userId: string;
  token?: string;
};

const initialValue = {
  organizationId: '',
  userId: '',
  token: undefined,
} satisfies AuthState;

const initialUserPermissionsState = [] satisfies UserPermission[];

export const organizationIdAtom = atom(initialValue.organizationId);

export const userIdAtom = atom(initialValue.userId);

export const tokenAtom = atomWithStorage<AuthState['token']>(
  'token',
  localStorage.getItem('token') ?? initialValue.token,
);

export const userPermissionsAtom = atom<UserPermission[]>(initialUserPermissionsState);

export const isLoggedInAtom = atom(
  (get) =>
    get(organizationIdAtom) !== initialValue.organizationId &&
    get(userIdAtom) !== initialValue.userId &&
    get(tokenAtom) !== initialValue.token,
);

export const useSetAuthState = () => {
  const setOrganizationId = useSetAtom(organizationIdAtom);
  const setUserId = useSetAtom(userIdAtom);
  const setToken = useSetAtom(tokenAtom);

  return useCallback(
    ({ organizationId, userId, token }: AuthState) => {
      setOrganizationId(organizationId);
      setUserId(userId);
      setToken(token);
    },
    [setOrganizationId, setUserId, setToken],
  );
};

export const useResetAuthState = () => {
  const setAuthState = useSetAuthState();

  return useCallback(() => setAuthState(initialValue), [setAuthState]);
};

export const useResetUserPermissionsAtom = () => {
  const setUserPermissions = useSetAtom(userPermissionsAtom);

  return useCallback(() => setUserPermissions(initialUserPermissionsState), [setUserPermissions]);
};
